import React, { Component } from "react";

import { updateNotify } from "../../../reduxState/actions/dashboardUI";
import {
  customInput,
  CustomSelect,
  customDate,
  customDOB,
  customMultiSelect,
  customTextArea,
  Email,
} from "../../Input/editable";
import {
  required,
  email,
  requiredHTML,
  minLengthThirty,
  minAge,
  isPhoneNumber,
  requiredSelect,
} from "../../validation/validate";
import { Field } from "redux-form";
import EditableQuill from "../../Input/editable-quill";

import formFields from '../../../config/profile/basics.json';

class BasicInfo extends Component {
  handleOnSubmit = (e) => {
    e.preventDefault();
    if (!this.props.valid) {
      this.props.dispatch(
        updateNotify({
          state: true,
          type: "error",
          message: `Some required fields are not completed`,
        })
      );
      return;
    }
    this.props.handleSubmit(e);
  };

  render() {
    const structure = formFields;
    const { backButton } = this.props;

    return (
      <form
        className="box-body"
        style={{ paddingBottom: "70px" }}
        onSubmit={this.handleOnSubmit}
      >
        {structure.map((detail) => {
          switch (detail.type) {
            case "text":
              return (
                <Field
                  helper={detail.helper}
                  key={detail.name}
                  name={detail.name}
                  type="text"
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  component={customInput}
                  validate={
                    detail.name === "contact_number"
                      ? [required, isPhoneNumber]
                      : detail.name === "email"
                        ? [required, email]
                        : detail.name === "license_number"
                          ? []
                          : [required]
                  }
                />
              );
            case "select":
              return (
                <Field
                  icon={detail.icon != null ? detail.icon : null}
                  key={detail.name}
                  label={detail.label != null ? detail.label : detail.name}
                  name={detail.name}
                  options={detail.options}
                  component={CustomSelect}
                  validate={[required]}
                />
              );
            case "date":
              return (
                <Field
                  helper={detail.helper}
                  key={detail.name}
                  icon={detail.icon != null ? detail.icon : null}
                  name={detail.name}
                  label={detail.label != null ? detail.label : detail.name}
                  component={customDate}
                  validate={[required]}
                />
              );
            case "dob":
              return (
                <Field
                  helper={detail.helper}
                  key={detail.name}
                  icon={detail.icon != null ? detail.icon : null}
                  name={detail.name}
                  label={detail.label != null ? detail.label : detail.name}
                  component={customDOB}
                  validate={[minAge, required]}
                />
              );
            case "multi-select":
              return (
                <Field
                  key={detail.name}
                  name={detail.name}
                  type={detail.type}
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  options={detail.options}
                  component={customMultiSelect}
                  validate={
                    [requiredSelect]
                  }
                />
              );
            case "rte":
              return (
                <Field
                  key={detail.name}
                  helper={detail.helper}
                  name={detail.name}
                  type={detail.type}
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  options={detail.options}
                  placeholder={detail.placeholder}
                  component={EditableQuill}
                  validate={[requiredHTML, minLengthThirty]}
                />
              );
            case "textarea":
              return (
                <Field
                  key={detail.name}
                  name={detail.name}
                  type={detail.type}
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  options={detail.options}
                  placeholder={detail.placeholder}
                  component={customTextArea}
                  validate={[required]}
                />
              );

            case "email":
              return (
                <Field
                  key={detail.name}
                  name={detail.name}
                  type={detail.type}
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  component={Email}
                  validate={
                    detail.type === "email" ? [required, email] : [required]
                  }
                />
              );
            default:
              return (
                <Field
                  key={detail.name}
                  name={detail.name}
                  type={detail.type}
                  label={detail.label != null ? detail.label : detail.name}
                  icon={detail.icon}
                  supportingText={detail.supporting_text}
                  component={customInput}
                  validate={
                    detail.type === "email" ? [required, email] : [required]
                  }
                />
              );
          }
        })}
        <div className="box-footer mobile-footer">
         {backButton ? (
          <button
            className="pill pull-left"
            type="button"
            onClick={this.props.goBack}
          >
            Back
          </button>
          ) : null}
          <button className="pill solid-blue pull-right" type="submit">
            {backButton ? "Next" : "Update"}
          </button>
        </div>
      </form>
    );
  }
}

export default BasicInfo;
