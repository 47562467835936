import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Malformed extends Component {

  componentDidMount() {
    if (document.getElementsByClassName("content-wrapper").length > 0) {
      document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor = "#87b1de"
    }
  }

  redirect = () => {
    this.props.history.replace('/logout');
  }

  render() {
    return (
      <main className="content" style={{ backgroundColor: "#87b1de", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <section className="row">
          <div className="col-xs-12">
            <div className="pending">
              <h2>Oops! Seems request is malformed!</h2>
              <br />
              <p><button style={{ color: "white" }} onClick={this.redirect}>Click <span className="btn">here</span> to login!</button></p>
            </div>
          </div>
        </section>
      </main>
    )
  }
}


export default Malformed;
