
import { reduxForm } from 'redux-form';
import ListContainer from '../Input/List/ListContainer';

const UserList = reduxForm({
    form: 'list',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(ListContainer);

export default UserList;