import React, { Component } from "react";
import List from "../../components/Profile/List/List";
import LanguagesList from "../../components/LanguagesList";
import Avatar from "../../components/Avatar";
import Moment from 'moment';

class PublicProfileScreen extends Component {

  createMarkup(text) {
    return { __html: text };
  }

  render() {
    const {
      gender,
      last_name,
      name,
      age_group,
      picture,
      summary,
      spoken_languages,
    } = this.props.userData.basics;

    const pictureLink =
      picture != null && picture.length > 0
        ? `https://process.filestackapi.com/${picture.substring(
          picture.lastIndexOf("/")
        )}`
        : `/assets/avatar/${gender === "female" ? "f" : "m"}-adult.png`;

    const haveCerts =
      this.props.userData.certifications &&
      this.props.userData.certifications.length > 0;

    const haveWorkExperience = this.props.userData.work &&
      this.props.userData.work[0] &&
      Object.keys(this.props.userData.work[0]).length > 0;

    const haveEducation = this.props.userData.education &&
      this.props.userData.education.length > 0 &&
      Object.keys(this.props.userData.education[0]).length > 0;

    const haveSummary = summary && summary.length > 0;
    const generalRating = [];

    if (this.props.ratings) {
      for (let i = 0; i < Math.ceil(this.props.ratings.average_appointment); i++) {
        generalRating.push(<img alt="star" key={i} src="/assets/star.svg"/>);
      }
    }

    return (
      <div className="preview-background row">
        <main className="preview col-lg-offset-3 col-lg-6 col-sm-12">
          <div className="preview-content">
            <img
              className="preview-logo"
              alt="jaga-me"
              src="https://cdn.jaga-me.com/logos/v2/logo.white.large.png"
              width="86px"
            />
            <span className="preview-profile-picture">
              <Avatar
                img={pictureLink}
                gender={gender}
                className="preview-avatar"
              />
            </span>
            <span className="preview-name">
              <h3>
                {name && name} {last_name && last_name} { generalRating }
              </h3>
            </span>
          </div>

          <section className="preview-details">
            <article className="preview-section box-body">
              <div className="preview-sub-section">
                <p>
                  <b>
                    {`${gender &&
                      `${gender[0].toUpperCase()}${gender.substring(
                        1,
                        gender.length
                      )}`
                      }${age_group && `, Age Group (${age_group} years old)`}`}
                  </b>
                </p>

                {(spoken_languages && spoken_languages.length) > 0 && <LanguagesList input={spoken_languages} />}
              </div>
              {haveSummary && <div
                className="preview-summary"
                dangerouslySetInnerHTML={this.createMarkup(summary)}
              />}
            </article>
            {haveWorkExperience && <article className="preview-section">
              <header className="box-header">
                <h3>Work Experience</h3>
              </header>
              <List
                listData={this.props.userData.work
                  .filter(workExperience =>
                    workExperience.position &&
                    workExperience.position.trim().length > 0 &&
                    workExperience.company &&
                    workExperience.company.trim().length > 0 &&
                    workExperience.startDate &&
                    workExperience.startDate.length > 0
                  )
                  .map(workExperience => ({
                    img: this.props.config.org_logos.find(org => org.full_name === workExperience.company) ?
                      this.props.config.org_logos.find(org => org.full_name === workExperience.company).image_url :
                      "https://cdn.jaga-me.com/assets/organisations/logos/partners_blank.jpg",
                    title: workExperience.position,
                    subtitle: workExperience.company + ', ' + workExperience.department,
                    subSubtitle: Moment(workExperience.startDate).format("MMMM YYYY") +
                      ' - ' +
                      (workExperience.endDate ? Moment(workExperience.endDate).format("MMMM YYYY"): 'Present'),
                    description: workExperience.summary
                  }))}
              />
            </article>}
            {haveEducation && <article
              className="preview-section"
              style={{ border: haveCerts ? null : "none" }}
            >
              <header className="box-header">
                <h3>Education</h3>
              </header>
              <List
                listData={this.props.userData.education
                  .filter(education =>
                    education.degree &&
                    education.degree.trim().length > 0 &&
                    education.institute &&
                    education.institute.trim().length > 0 &&
                    education.year &&
                    education.year.length > 0
                  )
                  .map(
                  (education) => ({
                    img: this.props.config.org_logos.find(org => org.full_name === education.institute) ?
                      this.props.config.org_logos.find(org => org.full_name === education.institute).image_url :
                      "https://cdn.jaga-me.com/assets/organisations/logos/school.jpg",
                    title: education.degree,
                    subtitle: education.institute,
                    subSubtitle: "Graduated in " + education.year,
                    description: null
                  })
                )}
              />
            </article>}
            {haveCerts && <article className="preview-section" style={{ border: "none" }}>
              <header className="box-header">
                <h3>Certifications</h3>
              </header>
              <List
                listData={this.props.userData.certifications
                  .filter(certification =>
                    certification.name &&
                    certification.name.trim().length > 0 &&
                    certification.authority &&
                    certification.authority.trim().length > 0 &&
                    certification.issued_date &&
                    certification.issued_date.length > 0
                  )
                  .map(
                  (certification) => ({
                    img: this.props.config.org_logos.find(org => org.full_name === certification.authority) ?
                      this.props.config.org_logos.find(org => org.full_name === certification.authority).image_url :
                      "https://cdn.jaga-me.com/assets/organisations/logos/partners_blank.jpg",
                    title: certification.name,
                    subtitle: certification.authority,
                    subSubtitle: Moment(certification.issued_date).format("MMMM YYYY"),
                    description: null
                  })
                )}
              />
            </article>}
          </section>
        </main>
      </div>
    );
  }
}

export default PublicProfileScreen;
