import React, { Component } from "react";

class SwabberZohoForm extends Component {
  render() {
    return (
      <div className="preview-background row">
        <main className="preview col-lg-offset-3 col-lg-6 col-sm-12">
          <div className="preview-content">
            <img
              className="preview-logo"
              alt="jaga-me"
              src="https://cdn.jaga-me.com/logos/v2/logo.white.large.png"
              width="86px"
            />

            <span className="preview-name">
              <h3>Sign Up for Swabber</h3>
            </span>
          </div>

          <iframe
          title="swabber zoho form"
            width="100%"
            height="1500px"
            src="https://jaga.zohorecruit.com/recruit/WebFormServeServlet?rid=c69b8289d1aa342c09d0b2b2a87c561bf7f13b917118a3e6894d9ea27f0530b3gida0dc8bcae746efdade39014c3bbace0d"
          />
        </main>
      </div>
    );
  }
}

export default SwabberZohoForm;
