import React, { Component } from 'react';
import { connect } from 'react-redux';

//  custom components
import AddProfilePhoto from './Panes/AddProfilePhoto';
import RegisterBasics from './Panes/RegisterBasics';
import RegisterBankInfo from './Panes/RegisterBankInfo';
import RegisterWorkExperiences from './Panes/RegisterWorkExperiences';
import RegisterEducation from './Panes/RegisterEducation';
import RegisterCertifications from './Panes/RegisterCertifications';
import RegisterCompletion from './Panes/RegisterCompletion';

import { changeTabs } from '../../reduxState/actions/register';
import { updateNotify } from '../../reduxState/actions/dashboardUI';

class RegisterPanes extends Component {
  submitBasics = (values) => {
    let submissionData = JSON.parse(JSON.stringify(values));
    delete submissionData['email'];
    this.props.editUserBasics(submissionData);
    this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
  };

  submitBankAccount = (values) => {
    this.props.updateBankAccount(values);
    this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
  };

  submitWork = (values) => {
    if (values.work_experiences && values.work_experiences.length > 0) {
      this.props.upsertWork(values.work_experiences);
      this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
    } else {
      this.props.dispatch(
        updateNotify({
          state: true,
          type: 'error',
          message: `Add at least one work experience`,
        })
      );
    }
  };

  submitEducation = (values) => {
    if (values.education && values.education.length > 0) {
      this.props.upsertEducation(values.education);
      this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
    } else {
      this.props.dispatch(
        updateNotify({
          state: true,
          type: 'error',
          message: `Add at least one education record`,
        })
      );
    }
  };

  submitCertifications = (values) => {
    if (values.certifications && values.certifications.length > 0) {
      this.props.upsertCertifications(values.certifications);
      this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
      return;
    }
    this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
  };

  nextPage = () => {
    this.props.dispatch(changeTabs(this.props.register.currentTab + 1));
  };

  handleCompletion = () => {
    this.props.completeProfile(() => {
      this.props.history.push('/setup');
    });
  };

  handleBack = () => {
    this.props.dispatch(changeTabs(this.props.register.currentTab - 1));
  };

  render() {
    if (this.props.register.tabs.length > 0) {
      let pane = null;
      const { tabs, currentTab } = this.props.register;
      const { fields, field_header, item_structure, helper } = tabs[currentTab];
      // switch case inside map
      switch (fields) {
        case 'profilePhoto':
          pane = (
            <AddProfilePhoto
              {...this.props}
              currentTab={currentTab}
              config={tabs[currentTab]}
              handleSubmit={this.nextPage}
            />
          );
          break;
        case 'basics':
          pane = (
            <RegisterBasics
              availability_section={false}
              backButton={true}
              goBack={this.handleBack}
              onSubmit={this.submitBasics}
              config={this.props.config}
              initialValues={{
                name: this.props.userData.basics.name,
                lastName: this.props.userData.basics.lastName,
                dob: this.props.userData.basics.dob,
                gender: this.props.userData.basics.gender,
                contact_number: this.props.userData.basics.contact_number,
                email: this.props.userData.basics.email,
                spokenLanguages: this.props.userData.basics.spokenLanguages,
                license_number: this.props.userData.basics.license_number,
                country: this.props.userData.basics.country,
                national_id: this.props.userData.basics.national_id,
                address1: this.props.userData.basics.address1,
                postal: this.props.userData.basics.postal,
                picture: this.props.userData.basics.picture,
              }}
            />
          );
          break;
        case 'bank_account':
          pane = (
            <RegisterBankInfo
              backButton={true}
              goBack={this.handleBack}
              onSubmit={this.submitBankAccount}
              config={this.props.config}
              initialValues={this.props.userData.bank_account}
              structure={
                tabs.find(({ fields }) => 'bank_account' === fields)
                  .item_structure
              }
            />
          );
          break;
        case 'work_experiences':
          pane = (
            <RegisterWorkExperiences
              currentTab={currentTab}
              backButton={true}
              goBack={this.handleBack}
              helper={helper}
              onSubmit={this.submitWork}
              initialValues={{
                work_experiences: this.props.userData.work_experiences,
              }}
              item_structure={item_structure}
              type={fields}
              header={field_header}
            />
          );
          break;
        case 'education':
          pane = (
            <RegisterEducation
              currentTab={currentTab}
              backButton={true}
              goBack={this.handleBack}
              helper={helper}
              onSubmit={this.submitEducation}
              initialValues={{ education: this.props.userData.education }}
              item_structure={item_structure}
              type={fields}
              header={field_header}
            />
          );
          break;
        case 'certifications':
          pane = (
            <RegisterCertifications
              currentTab={currentTab}
              backButton={true}
              goBack={this.handleBack}
              helper={helper}
              onSubmit={this.submitCertifications}
              initialValues={{
                certifications: this.props.userData.certifications,
              }}
              item_structure={item_structure}
              type={fields}
              header={field_header}
            />
          );
          break;
        default:
          pane = (
            <RegisterCompletion
              picture={this.props.userData.picture}
              gender={this.props.userData.gender}
              config={tabs[currentTab]}
              onSubmit={this.handleCompletion}
            />
          );
          break;
      }
      return <div className="register-content">{pane}</div>;
    } else {
      return null;
    }
  }
}

// dispatch is not present therefore need connect
export default connect()(RegisterPanes);
