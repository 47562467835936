import React, { Component } from "react";
import { connect } from "react-redux";

import PublicProfileScreen from "./PublicProfile.page";

import { getPublicProfile, getRatings } from "../../reduxState/actions/userData";
import { getConfig } from "../../reduxState/actions/config";
import { getOrgLogos } from "../../reduxState/actions/profile";
import Loading from "../../components/Loading";

class PublicProfileContainer extends Component {

  componentDidMount() {
    this.props.getConfig();
    this.props.getOrgLogos();
    this.props.getPublicProfile(this.props.match.params.jagapro_username);
    this.props.getRatings(this.props.match.params.jagapro_username);
  }

  render() {
    if (
      this.props.config &&
      this.props.userData &&
      this.props.userData.basics &&
      this.props.config.org_logos
    ) {
      return <PublicProfileScreen {...this.props} />;
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    config: state.config,
    ratings: state.ratings,
  };
};

export default connect(mapStateToProps, {
  getPublicProfile,
  getConfig,
  getOrgLogos,
  getRatings,
})(PublicProfileContainer);
