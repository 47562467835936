const client = window.filestack.init(process.env.REACT_APP_FS_API_KEY);

class Picker {

  set({ s3_path, filename, fromSources, transformations, onUploadDone }) {

    let options = {
      fromSources: fromSources ? fromSources : ['local_file_system', 'facebook', 'instagram'],
      accept: ['image/jpeg'],
      lang: 'en',
      maxSize: 5 * 1024 * 1024,
      maxFiles: 1,
      transformations,
      storeTo: {
        location: 's3',
        path: s3_path,
      },
      onFileSelected: file => {
        let file_size_limit = 5 * 1024 * 1024
        if (file.size > file_size_limit) {
          alert('Please select a file smaller than ' + 5 + 'MB');
          throw new Error('Please select a file smaller than ' + 5 + 'MB');
        }
        file.name = filename;
        return file
      },
    }

    if (onUploadDone) {
      options["onUploadDone"] = onUploadDone;
    }

    return client.picker(options)
    // this.client
  };

  close() {
    return client.close()
  }

};

export default Picker;