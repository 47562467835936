import React from 'react';

const CompletedProfile = () => (
  <p>
    Our clients trust Jaga-Me because they can see our JagaPros’ outstanding
    background and experiences. Setup your online profile to reassure patients
    and families that they’re in good hands.
    <br/>
  </p>
);

export default CompletedProfile;
