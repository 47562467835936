import { getUserId } from '../../utils/auth0.utils';
import { formatArrayToGQLString } from "./utils";
const baseUrl = process.env.REACT_APP_PROS_API_ENDPOINT;

const getMilestones = ()=>{
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_milestones`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
}

const fetchUserData = () => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro/${getUserId()}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
};

const fetchPublicUserData = (username) => {
  const options = {
    method: 'GET',
    mode: 'cors',
  };
  const uri = `${baseUrl}/user/username/${username}`;
  return fetch(uri, options);
};

const fetchPutUser = (data) => {
  const token = localStorage.getItem("id_token");
  const profile = JSON.parse(localStorage.getItem("profile"));
  const body = JSON.stringify(data);

  const headers = {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  };

  const uri = `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro/${profile.email}`;
  return fetch(uri, headers);
};

const putUser = (data) => {
  if(data.spokenLanguages){
    data.spokenLanguages = formatArrayToGQLString(data.spokenLanguages)
  }
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro/${getUserId()}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({data}),
    }
  );
};

const putBankAccount = (data) => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro/${getUserId()}/bank_account`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({data}),
    }
  );
};

const postWorkExperiences = (data) => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_work_experiences`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({data}),
    }
  );
};

const postEducation = (data) => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_education`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({data}),
    }
  );
};

const postCertifications= (data) => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_certifications`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({data}),
    }
  );
};

const postCompletedProfile = () => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_completed_profile`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
};

const fetchRatings = (username) => {
  const options = {
    method: 'GET',
    mode: 'cors',
  };
  const uri = `https://ratings.jaga-api.com/v1/jagapro/${username}/summary`;
  return fetch(uri, options);
};

const postCompletedDocumentSubmission = () => {
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro_complete_document_submission`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
};

const getStatus = ()=>{
  return fetch(
    `${process.env.REACT_APP_HASURA_REST_ENDPOINT}/pro/${getUserId()}/status`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
}



export { 
  getMilestones,
  fetchUserData, 
  fetchPublicUserData,
  fetchPutUser,
  putUser,
  putBankAccount,
  fetchRatings,
  postWorkExperiences,
  postEducation,
  postCertifications,
  postCompletedProfile,
  postCompletedDocumentSubmission,
  getStatus
};
