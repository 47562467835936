import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

class ProfileBox extends Component {

  handleLogOut = () => this.props.onHandleLogOut(this.props);
  handleClickOutside = () => this.props.clickOutside();

  render() {
    const { pictureLink, name } = this.props;
    return (
      <ul className="dropdown-menu" style={{ display: "block" }}  >
        <li className="user-header">
          <img src={pictureLink} className="img-circle" alt="user avatar" />
          <p>
            {name}
          </p>
        </li>

        <li className="user-footer">
          <div className="pull-left">
            <Link to="/profile" onClick={this.handleClickOutside} className="btn btn-default btn-flat">Profile</Link>
          </div>
          <div className="pull-right">
            <button className="btn btn-default btn-flat" onClick={this.handleLogOut}>Sign out</button>
          </div>
        </li>
      </ul>)
  };
};

export default onClickOutside(ProfileBox);