import React from 'react';

const AwaitingConfirmationSwabber = () => (
  <div>
    <p>
      Congratulations! Your account is all set up with us! Please wait for our
      staff to verify all the official documents that you have submitted before
      logging into the app
    </p>
    <div
      className="col-xs-12"
      style={{ display: 'flex', padding: '0px', paddingBottom: '15px' }}
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.jagapros"
        style={{ marginLeft: '-10px' }}
      >
        <img
          alt="Get it on Google Play"
          height="60"
          src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
        />
      </a>
      <a
        href="http://jaga.sg/pros/mobile"
        style={{
          marginTop: '10px',
          display: 'inline-block',
          overflow: 'hidden',
          background: `url('//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg')`,
          width: '135px',
          height: '40px',
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          border: '0px',
        }}
      ><span style={{display:"none"}}>apple</span></a>
    </div>
  </div>
);

export default AwaitingConfirmationSwabber;
