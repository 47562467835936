import { connect } from "react-redux";
import Profile from "./Profile";
import { updateProfileAndReloadPage, getOrgLogos } from "../../reduxState/actions/profile";

const mapStatetoProps = ({ user, userData, config }) => {
  return {
    user,
    userData,
    config,
  };
};

const ProfileContainer = connect(mapStatetoProps, {
  getOrgLogos,
  updateProfileAndReloadPage
})(Profile);

export default ProfileContainer;
