import React, { Component } from 'react';
import { connect } from 'react-redux';

//  action
import { updateNotify } from '../../reduxState/actions/dashboardUI';
import DropDown from '../Transitions/DropDown';

// use updateNotify to update notification

class Notification extends Component {

  componentDidMount() {
    this.timerID = setTimeout(() =>
      this.props.dispatch(updateNotify({ state: false }))
      , 2000);
  }

  componentDidUpdate() {
    this.timerID2 = this.props.dashboardUI.notification.state === true ? setTimeout(() =>
      this.props.dispatch(updateNotify({ state: false }))
      , 2000) : null;
  }

  componentWillUnmount() {
    this.props.dispatch(updateNotify({ state: false }))
    clearInterval(this.timerID);
    clearInterval(this.timerID2);
  }
  handleClick = () => {
    this.props.dispatch(updateNotify({ state: false }))
    clearInterval(this.timerID);
    clearInterval(this.timerID2);
  }

  render() {
    const { state, type, message } = this.props.dashboardUI.notification;
    return (<DropDown in={state} >{<main className='notify-screen container' onClick={this.handleClick}>
      <div className="row">
        <div className="notify col-lg-offset-8 col-xs-12 col-lg-4">
          <div className={`shadow ${type != null ? type : 'success'}`}>
            <b>{type ? `${type[0].toUpperCase()}${type.substring(1, type.length)}` : null}</b>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </main>}</DropDown>)
  }
}

const getDashboardUI = dashboardUI => dashboardUI

const mapStateToProps = state => {
  return {
    dashboardUI: getDashboardUI(state.dashboardUI)
  }
}

export default connect(mapStateToProps)(Notification);