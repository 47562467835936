import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

const DropDown = ({ children, ...props }) => (
    <CSSTransition
      {...props}
      timeout={500}
      classNames="dropdown"
      mountOnEnter
      appear
      unmountOnExit
    >
     {children}
    </CSSTransition>
   );

export default DropDown;