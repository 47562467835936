import React from 'react';

const CompletedFreelanceAgreement = () => (
  <div>
    <p>
      This contract details the roles and responsibilities of both the Platform
      and you.
    </p>
    <p>
      Please read through it carefully and sign if you agree to be on board with
      us.
    </p>
    <br />
  </div>
);

export default CompletedFreelanceAgreement;
