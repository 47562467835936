import vocationOrder from '../config/vocationOrder.json'

export const selectVocation = (vocations = []) => {
  let selectedVocationOrder = vocationOrder.length;
  let selectedVocation = null;

  vocations.forEach((vocation, index)=>{
    if( vocationOrder.some(vocationKey=> vocationKey === vocation)){
      if(selectedVocationOrder > index){
        selectedVocation = vocation;
      }
    }
  })

  return selectedVocation;
};