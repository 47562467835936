import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { customInput, CustomSelect, customDate, customTextArea, CustomDateSelect } from '../editable';
import customCreatable from '../customCreatable'
import { required, requiredHTML } from '../../validation/validate';
import Moment from 'moment';
import AnimateHeight from 'react-animate-height';

import { years } from '../editable';

const createMarkup = (text) => {
  return { __html: text };
};

class ListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expand: props.fields.get(props.index) === undefined ? true : false
    }
  }

  handleClick = () => {
    this.setState({ expand: !this.state.expand })
  }

  render() {

    const { fields, item_structure, header, index, options, type } = this.props;
    const { name } = fields;

    let validateClass = ""

    // ADDING DISPLAY
    const data = fields.get(index);

    const validateTitle = data != null && data[header] != null && data[header].length > 0
    if (!validateTitle) {
      validateClass = "has-error"
    }

    const titleElement = validateTitle ? data[header] : `New ${header}`;
    let keys = Object.keys(item_structure).filter(key => (key !== header && key !== 'department' && key !== 'company'));

    // additional filtering for certifications
    keys = (name === 'certifications') ? keys.filter(key => !(key === 'description' || key === 'number')) : keys;

    const actions =
      // !this.props.showOnly ?
      <footer className="box-footer">
        <button className='pill pull-left' type="button" onClick={() => fields.remove(index)}>delete</button>
        <button className='pill pull-right' type="button"
          onClick={this.handleClick}
        >Confirm</button>
      </footer>
    // : null;

    let details = [];

    // switch
    keys.map((key, i) => {
      let isValid = data != null && data[key] != null && data[key].length > 0;
      if (item_structure[key].validate !== "not required") {
        if (!isValid) {
          validateClass = "has-error"
        }
      }
      switch (item_structure[key].type) {
        case ('text'):
          isValid = data != null && data[key] != null && data[key].length > 0;
          details.push(
            <div className={isValid ? "" : "has-error"} key={`${key} ${index} ${i}`} >{isValid ? data[key] : `New ${key}`}</div>
          )
          break;
        case ('date'):
          if (key === 'endDate') {
            isValid = data != null && data[key] != null && data[key].length > 0
            details.push(<div className={isValid ? "" : "has-error"} key={`${key} ${index} ${i}`}>{isValid ? Moment(data[key]).format("MMMM YYYY") : 'Present'}</div>)
          } else if (data != null && data.startDate != null && data.endDate == null) {
            isValid = data != null && data[key] != null && data[key].length > 0
            details.push(<div className={isValid ? "" : "has-error"} key={`${key} ${index} ${i}`}>{isValid ? Moment(data[key]).format("MMMM YYYY") : key}</div>)
          }
          break;
        case ('textarea'):
          isValid = data != null && data[key] != null && data[key].length > 0
          details.push(
            <p className={isValid ? "" : "has-error"} key={`${key} ${i}`}
              style={{ paddingTop: '1rem' }}
              dangerouslySetInnerHTML={createMarkup(isValid ? data[key] : `New ${key}`)} />
          )
          break;
        default:
          isValid = data != null && data[key] != null && data[key].length > 0
          details.push(<div className={isValid ? "" : "has-error"} key={`${key} ${index} ${i}`}>{isValid ? data[key] : `New ${key}`}</div>)
      }
      return key
    });

    if (data != null && data.company != null) {
      const validateCompany = data.company != null && data.company.length > 0;
      const validateDepartment = data.department != null && data.department.length > 0;

      if (!validateCompany) {
        validateClass = "has-error"
      }

      if (!validateDepartment) {
        validateClass = "has-error"
      }

      const company = validateCompany ? data.company : "company";
      const department = validateDepartment ? data.department : "departmant";
      details = [(<div key={`${company} ${index}`}><span>{company}</span>, <span >{department}</span></div>), ...details]
    };

    // start and end date same line
    if (data != null && data.startDate != null && data.endDate != null) {
      const validateStartDate = data.startDate.length > 0;
      if (!validateStartDate) {
        validateClass = "has-error"
      };

      const start_date = data.startDate.length > 0 ? Moment(data.startDate).format('MMM YYYY') : "Present";
      const end_date = data.endDate.length > 0 && data.endDate !== 'present' ? Moment(data.endDate).format('MMM YYYY') : data.endDate;

      details = [...details.slice(0, 1), (<div key={`start-end-time ${index}`}><span>{start_date}</span> - <span>{end_date}</span></div>), ...details.slice(1, details.length)]
    };

    //  ADDING DISPLAY

    const current_org = data == null ? "" : data.company != null ? data.company : data.institute != null ? data.institute : data.authority ? data.authority : "";

    const org_logo = options.find(org => org.full_name === current_org);

    return (
      <li className={`row ${this.props.showOnly ? '' : 'service-tab'}`} style={{ listStyleType: "none", display: "flex", alignItems: "flex-start" }} key={name + index}>
        <section className="col-sm-3 centre hide-on-small" style={{ backgroundColor: '#fff' }} onClick={() => this.setState({ expand: !this.state.expand })}>
          <img style={{ margin: "1.2rem" }} height="85px" src={org_logo !== undefined ? org_logo.image_url : `https://cdn.jaga-me.com/assets/organisations/logos/${name === 'education' ? "school" : "partners_blank"}.jpg`} alt={''} />
        </section>
        <article className={`col-xs-12 col-sm-9`} style={{ backgroundColor: '#fff' }}>
          {!this.state.expand ?
            <div style={{ padding: '1rem 0px', color: validateClass === "has-error" ? "#dd4b39" : "" }} onClick={() => this.setState({ expand: !this.state.expand })}>
              <div style={{ fontWeight: 700, fontSize: '16px' }} >{titleElement}</div>
              {details}
            </div> : null}

          <AnimateHeight
            duration={500}
            height={this.state.expand ? 'auto' : 0}
            style={{ paddingTop: "1rem", paddingBottom: "1rem", }}
          >
            {Object.keys(item_structure).map(key => {
              const detail = item_structure[key]
              const validate = item_structure[key].validate != null && item_structure[key].validate === 'not required' ? [] : detail.type === "rte" ? [requiredHTML] : detail.type === "creatable" ? [required] : [required];
              switch (detail.type) {
                case ('text'):
                  return <Field
                    helper={detail.helper}
                    key={key + index}
                    name={`${fields.name}[${index}].${key}`}
                    type="text"
                    label={detail.label != null ? detail.label : key}
                    supportingText={detail.supporting_text}
                    component={customInput}
                    validate={validate}
                  />
                case ('select'):
                  return <Field
                    key={key + index}
                    label={detail.label != null ? detail.label : key}
                    name={`${fields.name}[${index}].${key}`}
                    options={detail.options !== 'years' ? detail.options : years()}
                    component={CustomSelect}
                    blur={false}
                    validate={validate}
                  />
                case ('date'):
                  return <Field
                    key={key + index}
                    name={`${fields.name}[${index}].${key}`}
                    label={detail.label != null ? detail.label : key}
                    component={type === 'work_experiences' ? CustomDateSelect : customDate}
                    validate={validate}
                    supportingText={detail.supporting_text}
                  />
                case ('textarea'):
                  return <Field
                    key={key + index}
                    name={`${fields.name}[${index}].${key}`}
                    type={detail.type}
                    label={detail.label != null ? detail.label : key}
                    supportingText={detail.supporting_text}
                    options={detail.options}
                    placeholder={detail.placeholder}
                    component={customTextArea}
                    validate={validate}
                  />
                case ('creatable'):
                  return <Field
                    key={key + index}
                    name={`${fields.name}[${index}].${key}`}
                    type={detail.type}
                    label={detail.label != null ? detail.label : key}
                    supportingText={detail.supporting_text}
                    options={detail.options}
                    placeholder={detail.placeholder}
                    url="https://cdn.jaga-me.com/assets/organisations/index.json"
                    component={customCreatable}
                    validate={validate}
                  />
                default:
                  return <Field
                    key={key + index}
                    name={`${fields.name}[${index}].${key}`}
                    type={detail.type}
                    label={detail.label != null ? detail.label : key}
                    supportingText={detail.supporting_text}
                    component={customInput}
                    validate={validate}
                  />
              }
            })}
            {actions}
          </AnimateHeight>
        </article>
      </li>
    )
  }
}

const getOrgLogos = org_logos => org_logos

const mapStateToProps = state => {
  return {
    options: getOrgLogos(state.config.org_logos)
  }
}

export default connect(mapStateToProps)(ListItem);
