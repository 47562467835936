export const loadTabs = tabs => {
    return {
        type: "LOAD_REGISTER_TABS",
        payload: tabs
    }
};

export const changeTabs = currentTab => {
    return {
        type: "CHANGE_REGISTER_TAB",
        payload: currentTab
    }
};