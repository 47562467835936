export const getUserId = () => {
  const profileItem = localStorage.getItem('profile');
  if (!profileItem) {
    return null;
  }
  const profile = JSON.parse(profileItem);

  const profileClaims = profile['https://hasura.io/jwt/claims'];
  if (!profileClaims) {
    return null;
  }

  const userId = profileClaims['x-hasura-user-uuid'];
  return userId ? userId : null;
};
