import { reduxForm } from 'redux-form';
import ListContainer from '../../../components/Input/List/ListContainer';

const RegisterCertifications = reduxForm({
    form: 'certifications',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(ListContainer);

export default RegisterCertifications;
