import { loadTabs } from "./register";
import config from "../../config";

const updateConfig = (config) => {
  return {
    type: "UPDATE_CONFIG",
    payload: config,
  };
};

export const getConfig = () => {
  return (dispatch) => {
    dispatch(updateConfig(config));
    dispatch(loadTabs(config.register.tabs));
  };
};
