import React, { Component } from 'react';
import { connect } from 'react-redux';

import Register from './Register';

//  actions
import {
  editUser,
  editUserBasics,
  updateBankAccount,
  upsertWork,
  upsertEducation,
  completeProfile,
  upsertCertifications
} from '../../reduxState/actions/userData';
import { updateProfile, getOrgLogos } from '../../reduxState/actions/profile';
import { loadTabs } from '../../reduxState/actions/register';

//  utils
import { selectVocation } from '../../utils/jagapro.utils';

import vocationConfig from '../../config/setup/vocations.json';
import registerSteps from '../../config/setup/register/steps.json';

class RegisterContainer extends Component {

  componentDidMount(){
    this.props.getOrgLogos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData && prevProps.userData !== this.props.userData) {
      const { jagapro_status } = prevProps.userData;
      if (jagapro_status === 'completed') {
        this.props.history.push('/');
      }
      this.props.loadTabs(this.generateRegisterPanes().tabs);
    }
  }

  generateRegisterPanes = () => {
    const { vocations } = this.props.userData;
    const selectedVocation = selectVocation(vocations);

    const registerStages = vocationConfig[selectedVocation]
      ? vocationConfig[selectedVocation].register
      : null;

    return {
      ...this.props.register,
      tabs: registerStages
        ? registerStages.map((stageName) => {
            return registerSteps.find((tab) => tab.title === stageName);
          }):[]
    };
  };

  render() {
    if (this.props.userData != null) {
      return (
        <Register
          {...this.props}
          register={this.generateRegisterPanes()}
          loadTabs={this.props.loadTabs}
          updateProfile={this.props.updateProfile}
          editUser={this.props.editUser}
          userData={this.props.userData}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({
  dashboardUI,
  userData,
  register,
}) => {
  return {
    dashboardUI,
    userData,
    register,
  };
};

export default connect(mapStateToProps, {
  editUser,
  updateProfile,
  loadTabs,
  editUserBasics,
  updateBankAccount,
  upsertWork,
  upsertEducation,
  completeProfile,
  getOrgLogos,
  upsertCertifications
})(RegisterContainer);
