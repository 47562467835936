const config = (state = null, action) => {
    switch (action.type) {
        case ('LOAD_CONFIG'):
        // causes assignment and aync issues
            return action.payload
        case ('UPDATE_CONFIG'):
            return Object.assign({}, state, action.payload)
        default:
            return state;
    }
}

export default config;