import React from 'react';
import { FieldArray } from 'redux-form';
import ListItem from './ListItem';

const List = (props) => {
  const handleAdd = () => {
    props.fields.push();
  };

  return (
    <ul className="box-body">
      {props.fields.map((field, index) => (
        <FieldArray
          key={props.fields.name + index}
          name={props.fields.name}
          component={ListItem}
          props={{
            item_structure: props.item_structure,
            header: props.header,
            index,
            type: props.type,
          }}
        />
      ))}
      <footer
        className="box-footer"
        style={{ paddingRight: 0, paddingLeft: 0 }}
      >
        <button className="pill solid-blue pull-right" onClick={handleAdd}>
          Add
        </button>
      </footer>
    </ul>
  );
};

export default List;
