import { connect } from 'react-redux';
import Sidebar from './Sidebar';


const getUser = (user) => {
    return user
};

const readUserData = (userData) => {
    return userData
};

const mapStatetoProps = state => {
    return {
        user: getUser(state.user),
        userData: readUserData(state.userData)
    }
};

const SidebarContainer = connect(mapStatetoProps)(Sidebar);

export default SidebarContainer;