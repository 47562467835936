import React, { Component } from "react";
import { connect } from "react-redux";
import Review from "./Review";

//transition components
import Loading from "../../components/Loading";

//redux actions
import { addReviews } from '../../reduxState/actions/reviews';

const no_reviews_message = "You do not have any reviews yet. Take more cases with us!";

class ReviewsContainer extends Component {

  state = {
    events: null,
    loading: false
  };

  componentDidMount() {
    if (this.props.userData != null && this.props.reviews == null) {
      this.fetchReviews(this.props);
    }
  }

  // componentDidUpdate(newProps) {
  //   if (newProps.userData != null && newProps.reviews == null) {
  //     this.fetchReviews(newProps);
  //   }
  // }

  fetchReviews = (props) => {
    this.setState({ loading: true });
    const reviewRoute = `/user/username/${props.userData.basics.username}/ratings`;
    const uri = `${process.env[`REACT_APP_PROS_API_${process.env.REACT_APP_STAGE}`]}${reviewRoute}`;
    const options = {
      headers: {
        Authorization: localStorage.getItem("id_token")
      },
      method: "GET",
      mode: "cors"
    }
    fetch(uri, options)
      .then(res => {
        return res.json();
      })
      .then(json => {
        //store in redux store
        const filtered_ratings = json.ratings.filter(rating => rating.appointment >= 3);
        const filtered_results = Object.assign(json, { ratings: filtered_ratings });
        this.props.dispatch(addReviews(filtered_results));
      })
      .catch(err => {
        console.error(err);
      });
  };

  render() {
    let reviews = [];
    if (this.props.reviews != null) {
      if (this.props.reviews.ratings.length > 0) {
        this.props.reviews.ratings.map((rating, i) => {
          reviews.push(
            <Review key={i} index={i} rating={rating} />
          );
          return {rating, i};
        });
      } else {
        reviews.push(<div key="no review" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "400px" }}><span style={{ padding: "3rem", fontWeight: '600', }}>{no_reviews_message}</span></div>)
      }

      return (
        <main className="content">
          <section className="row justify-content-md-center">
            {reviews}
          </section>
        </main>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStatetoProps = state => {
  return {
    reviews: state.reviews,
    userData: state.userData
  };
};

export default connect(mapStatetoProps)(ReviewsContainer);
