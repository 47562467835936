import React, { Component } from "react";
import Moment from "moment";
//transition components
import Loading from "../../components/Loading";

import '../../stylesheets/reviews.css';

const moreStyle = {
    position: "absolute",
    bottom: "0px",
    right: "1rem",
    backgroundColor: "#fff",
    color: "#87b1de"
}

const collapseStyle = {
    position: "relative",
    textAlign: "center",
    width: "100%",
    backgroundColor: "#fff",
    color: "#87b1de"
}

function createMarkup(html) {
    return { __html: html };
}

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venue: null,
            text: "none",
            categories: null,
            subcategories: null,
            formats: null,
            show: false,
            expand: false,
            descriptionClassName: "rating-description inactivated"
        };
    };

    handleClick=()=>{
        if (this.state.descriptionClassName === "rating-description inactivated") {
            this.setState({ descriptionClassName: "rating-description activated", expand: true })
            setTimeout(() => {
                document.getElementById(`review-description-${this.props.index}`).style.overflow = 'visible';
            }, 400);
        } else {
            this.setState({ descriptionClassName: "rating-description inactivated", expand: false })
            document.getElementById(`review-description-${this.props.index}`).style.overflow = 'hidden';
        }
    };

    render() {

        let bottomText = this.state.expand ? <div style={collapseStyle}>collapse</div> : <span style={moreStyle} >{"[more...]"}</span>;

        if (this.props.rating != null) {

            let general_rating = []
            for(let i = 0;i < this.props.rating.appointment ;i++){
                general_rating.push(<img alt="star" key={i} src="/assets/star.svg" />)
            }

            let attitude_rating = []
            for(let i = 0;i < this.props.rating.attitude ;i++){
                attitude_rating.push(<img alt="star" key={i} src="/assets/star.svg" />)
            }

            let competency_rating = []
            for(let i = 0;i < this.props.rating.competency ;i++){
                competency_rating.push(<img alt="star" key={i} src="/assets/star.svg" />)
            }

            const attitudeElement = this.props.rating.customer_id != null ? <div className="col-xs-6" style={{ wordWrap: "break-word" }}>
            <p style={{ marginBottom: "0", paddingTop: "0.5rem"}}>Attitude</p>
            {attitude_rating}
            </div> : null;

            const competencyElement = this.props.rating.customer_id != null ? <div className="col-xs-6" style={{ wordWrap: "break-word" }}>
            <p style={{ marginBottom: "0", paddingTop: "0.5rem" }}>Competency</p>
            {competency_rating}
            </div> : null;

            return (
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <article className="generic-card" style={{ marginBottom: "1rem" }} onClick={this.handleClick}>

                        <div className="box-body event-body">
                            <div className="col-xs-9">
                            <p style={{ marginBottom: "0", color:"#5C63F4",    paddingTop: "0.5rem" }}>General Service</p>
                                {general_rating}
                            </div>

                            <div className="col-xs-3">
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: "10px" }}>
                                    <span style={{ color: "#f38487", marginRight: "1rem" }}>{Moment(this.props.rating.updated_at).format(
                                        "ddd"
                                    ).toUpperCase()}</span>
                                    <span style={{ color: "#989aa0", marginRight: "1rem" }}>{Moment(this.props.rating.updated_at).format(
                                        "DD"
                                    )}</span>
                                    <span style={{ color: "#989aa0" }}>{Moment(this.props.rating.updated_at).format(
                                        "MMM"
                                    ).toUpperCase()}</span>
                                </div>
                            </div>

                            {attitudeElement}
                            {competencyElement}
                            

                            <div className="col-xs-12" style={{marginTop:"1rem"}}>
                                <div id={`review-description-${this.props.index}`} className={this.state.descriptionClassName} dangerouslySetInnerHTML={createMarkup(this.props.rating.feedback)} />
                                {bottomText}
                            </div>
                        </div>

                    </article>
                </div>
            );
        } else {
            return <Loading/>;
        }
    }
}

export default Review;
