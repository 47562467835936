import React, { Component } from 'react';
import { connect } from 'react-redux';
import SetupScreen from './Setup.screen';

import { editUser, readMilestones } from '../../reduxState/actions/userData';
import { updateProfile } from '../../reduxState/actions/profile';

import { selectVocation } from '../../utils/jagapro.utils';
import { capitalizeAllFirstLetter } from '../../utils/common.utils';

import setupSteps from '../../config/setup/steps';
import vocationConfig from '../../config/setup/vocations';

class SetupContainer extends Component {
  componentDidMount() {
    this.props.readMilestones();
  }

  getAgreementFormUrl = (vocations) => {
    const selectedVocation = vocations[0];
    const url = vocationConfig[selectedVocation].formstack_contract_url;
    return url ? url : null;
  };

  formatRole = (vocations) => {
    return capitalizeAllFirstLetter(
      selectVocation(vocations).replace(/-/g, ' ')
    );
  };

  generateParams = (paramArray) => {
    let paramString = '';

    paramArray.forEach(({ key, value }) => {
      paramString =
        paramString +
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    });
    // remove last &
    paramString = paramString.substring(0, paramString.length - 1);
    return paramString;
  };

  generateAgreementFormUrl = () => {
    const {
      gdrive_folder_id,
      name,
      lastName,
      address1,
      postal,
      national_id,
      email,
      vocations,
    } = this.props.userData;
    const formParams = [
      {
        key: 'GDRIVE ID',
        value: gdrive_folder_id,
      },
      {
        key: 'Full Name',
        value: `${name} ${lastName}`,
      },
      {
        key: 'Street Name',
        value: address1,
      },
      {
        key: 'Postal',
        value: postal,
      },
      {
        key: 'NRIC',
        value: national_id,
      },
      {
        key: 'Email',
        value: email,
      },
      {
        key: 'role',
        value: this.formatRole(vocations),
      },
    ];
    const queryParams = this.generateParams(formParams);

    let agreement_url = this.getAgreementFormUrl(vocations);
    if(agreement_url){
      agreement_url = agreement_url + `?${queryParams}`;
    }

    return agreement_url;
  };

  generateOnboardingStages = () => {
    if (!this.props.userData || !this.props.userData.vocations) {
      return {};
    }
    
    // object.keys will have indexing inconsistency!
    const stages = vocationConfig[selectVocation(this.props.userData.vocations)]
      .setup.reduce((acc, stageName) => {
        acc[stageName] = setupSteps[stageName];
        return acc;
      }, {});

    if (stages.completed_freelance_agreement) {
      return {
        ...stages,
        completed_freelance_agreement: {
          ...setupSteps.completed_freelance_agreement,
          url: this.generateAgreementFormUrl(),
        },
      };
    } else {
      return stages;
    }
  };

  render() {
    if (this.props.userData.milestones) {
      return (
        <SetupScreen
          {...this.props}
          onboardingStages={this.generateOnboardingStages()}
        />
      );
    } else {
      return <p>...loading</p>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardUI: state.dashboardUI,
    userData: state.userData,
    user: state.user,
    form: state.form,
  };
};

export default connect(mapStateToProps, {
  editUser,
  updateProfile,
  readMilestones,
})(SetupContainer);
