import React from "react";
import { connect } from 'react-redux';
import Loading from './Loading';

const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        border:'none'
};

const Reimbursement = (props) => {
    let queryStrings = "";
    if (props.userData != null) {
        const { name, last_name, email, national_id } = props.userData.basics
        queryStrings = `?jagapro_email=${email}&jagapro_surname=${last_name}&jagapro_given_name=${name}&jagapro_nric=${national_id}`;
        return (<iframe src={`https://jaga.formstack.com/forms/jagapro_reimbursement${queryStrings}`} title="JagaPro Reimbursement" style={style}>
        </iframe>)
    } else {
        return <Loading />
    };
};

const readUserData = userData => userData

const getdashboardUI = dashboardUI => dashboardUI

const mapStateToProps = state => {
    return {
        userData: readUserData(state.userData),
        dashboardUI : getdashboardUI(state.dashboardUI)
    }
};

export default connect(mapStateToProps)(Reimbursement);