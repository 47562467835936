import React, { Component } from 'react';

class WizardItem extends Component {
  render() {
    const { children } = this.props;
    const lastIndex = Number(children[1].props.children.props.lastindex) -1
    const index = Number(children[1].props.children.props.index)
    const currentStatus = children[1].props.children.props.status

    return (
      <div style={{
        position: 'relative',
        borderLeft: currentStatus === 'current' ? '2px solid #503fb1' :
          lastIndex !== index ? '2px solid gray' :
            '2px solid white'
      }}>
        <span className="centre dot" style={{
          color: currentStatus === 'current' ? "white" : "gray",
          backgroundColor: currentStatus === 'current' ? "#503fb1" : "white",
          border: currentStatus === 'current' ? "2px solid #503fb1" : "2px solid gray"
        }}><b>{index + 1}</b></span>
        {children}
      </div>
    )
  }
}

export default WizardItem;
