import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { logout } from '../../reduxState/actions/index';
import { connect } from 'react-redux'

class ErrorPage extends Component {
    constructor(props) {
        super(props)
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        if (document.getElementsByClassName("content-wrapper").length > 0) {
            document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor = "#87b1de"
        }
    }

    redirect() {
        this.props.history.goBack();
    }

    logout() {
        // Clear user token and profile data from localStorage
        // localStorage.removeItem('id_token');
        // localStorage.removeItem('profile');
        this.props.dispatch(logout(this.props));
        this.props.history.replace("/login");
    }

    render() {
        return (
            <main className="content" style={{ backgroundColor: "#87b1de", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <section className="row">
                    <div className="col-xs-12">
                        <div className="pending">
                            <h2>Stay Calm</h2>
                            <p>If you see this page, contact Jaga-Me for support</p>
                            <p><button onClick={this.logout}>Click here</button> to return back to Login</p>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}


export default connect()(ErrorPage);
