import React, { Component } from 'react'
import {
  Route
} from 'react-router-dom'
import Loading from '../../components/Loading'
import Moment from 'moment'
import { Link } from 'react-router-dom'
import Picker from '../../components/FileStackPicker';
import UserInfoTab from '../../components/Profile/user-info-tabs';

import '../../assets/css/profile.css';

class Profile extends Component {

  picker = new Picker();

  state = {
    skills_expand: "150px",
  }

  componentDidMount() {
    this.props.getOrgLogos();
  }

  componentDidUpdate() {
    this.redirect(this.props);
  }

  redirect = (props) => {
    if (props.match.params.profile_section === undefined) {
      this.props.history.push("/profile/basic_info")
    }
  };

  handleClick = () => {
    const { id, username } = this.props.userData.basics;
    const date = Moment().format("DDMMYY");
    
    const config = {
      s3_path: `/jagapros-assets/documents/${id}/${date}_${username}_profile.png`,
      filename: `${date}_${username}_profile.png`,
      transformations: {
        crop: {
          aspectRatio: 1 / 1,
          force: true
        },
        rotate: true
      },
      onUploadDone: result => {
        this.props.updateProfileAndReloadPage(result.filesUploaded[0].url);
      },
    };
    this.picker.set(config).open();
  }

  render() {
    let positions = [];

    if (this.props.config != null && this.props.config.profile != null && this.props.userData != null) {
      const { picture, name, last_name, gender } = this.props.userData.basics;
      const { tabs } = this.props.config.profile
      const pictureLink = picture != null && picture.length > 0 ? `https://process.filestackapi.com/${picture.substring(picture.lastIndexOf("/"))}` : `/assets/avatar/${gender === 'female' ? 'f' : 'm'}-adult.png`;

      return (
        <div id="Profile">
          <main className="content">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12">
                <div className="box box-primary">
                  <article className="box-body box-profile">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      {this.props.userData.basics.loading ? <figure className="sm-loading-logo">
                        <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={this.props.type === "login" ? "white" : "#87b1de"}>
                          <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="1">
                              <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                              <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  from="0 18 18"
                                  to="360 18 18"
                                  dur="1s"
                                  repeatCount="indefinite" />
                              </path>
                            </g>
                          </g>
                        </svg>
                        <img className='centre' src={`https://cdn.jaga-me.com/logos/v2/logo.square.blue.png`} alt='spinner' />
                      </figure> : <img role="button" className="profile-user-img img-responsive img-circle"
                        onClick={this.handleClick} src={pictureLink}
                        alt="User Profile" />}
                    </div>
                    <div className="col-md-9 col-sm-9 col-xs-12 profile-text">
                      <h2 className="profile-username">{name}{" "}{last_name}</h2>
                      <ul className="list-unstyled" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {positions}
                      </ul>
                    </div>
                  </article>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-xs-12" >
                <div className="nav-tabs-custom">
                  <ul className="nav nav-tabs">
                    {tabs.map((tab, i) =>
                      <li key={`${tab.title} ${i} displayTabs`} className={tab.key === this.props.match.params.profile_section ? 'active' : ''}>
                        <Link to={`/profile/${tab.key}`}>
                          <i className={tab.icon} style={{ paddingRight: "1rem" }} />{tab.title}
                        </Link>
                      </li>
                    )}
                  </ul>
                  <div>
                    <Route path="/profile/:profile_section" component={UserInfoTab} />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )
    } else {
      return (
        <Loading />
      )
    }
  }
}

export default Profile;
