import Moment from 'moment';
import { updateNotify } from './dashboardUI';
import {
  fetchUserData,
  fetchPublicUserData,
  putUser,
  fetchRatings,
  getMilestones,
  putBankAccount,
  postWorkExperiences,
  postEducation,
  postCompletedProfile,
  fetchPutUser,
  postCompletedDocumentSubmission,
  postCertifications
} from '../api/userData';

const addRatings = (ratings) => {
  return {
    type: 'SET_RATINGS',
    payload: ratings,
  };
};

const addUserData = (userData) => {
  const sortedUserData = Object.assign({}, userData, {
    basics: userData.basics,
    work:
      userData.work != null && userData.work.length > 0
        ? userData.work.sort((a, b) => {
            let aEndDate =
              a.endDate === '' || a.endDate === 'present'
                ? Moment()
                : Moment(a.endDate);
            let bEndDate =
              b.endDate === '' || b.endDate === 'present'
                ? Moment()
                : Moment(b.endDate);
            return bEndDate - aEndDate;
          })
        : [],
    certifications:
      userData.certifications != null && userData.certifications.length > 0
        ? userData.certifications.sort((a, b) => {
            let aEndDate =
              a.issued_date === '' ? Moment() : Moment(a.issued_date);
            let bEndDate =
              b.issued_date === '' ? Moment() : Moment(b.issued_date);
            return bEndDate - aEndDate;
          })
        : [],
    education:
      userData.education != null && userData.education.length > 0
        ? userData.education.sort((a, b) => {
            let aEndDate = a.year === '' ? Moment() : Moment(a.year);
            let bEndDate = b.year === '' ? Moment() : Moment(b.year);
            return bEndDate - aEndDate;
          })
        : [],
    bank_account: userData.bank_account,
  });
  return {
    type: 'SET_USERDATA',
    payload: sortedUserData,
  };
};

export const editUserPicture = (picture) =>{
  return {
    type: 'SET_USER_PICTURE',
    payload: {picture},
  };
}

export const updateUserBasics = (payload) => {
  return {
    type: 'UPDATE_USER_BASICS',
    payload,
  };
};

const readUserData = () => {
  return (dispatch) =>
    fetchUserData()
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((json) => {
        const userData = json.pros_legacy_nurse_by_pk;
        const basics = {
          id: json.pros_legacy_nurse_by_pk.id,
          username: json.pros_legacy_nurse_by_pk.username,
          name: json.pros_legacy_nurse_by_pk.name,
          lastName: json.pros_legacy_nurse_by_pk.lastName,
          dob: json.pros_legacy_nurse_by_pk.dob,
          gender: json.pros_legacy_nurse_by_pk.gender,
          contact_number: json.pros_legacy_nurse_by_pk.contact_number,
          email: json.pros_legacy_nurse_by_pk.email,
          spokenLanguages: json.pros_legacy_nurse_by_pk.spokenLanguages,
          license_number: json.pros_legacy_nurse_by_pk.license_number,
          country: json.pros_legacy_nurse_by_pk.country,
          national_id: json.pros_legacy_nurse_by_pk.national_id,
          address1: json.pros_legacy_nurse_by_pk.address1,
          postal: json.pros_legacy_nurse_by_pk.postal,
          picture: json.pros_legacy_nurse_by_pk.picture,
        }
        dispatch(addUserData({
          ...userData,
          basics
        }));
      })
      .catch((err) => {
        alert('Error when getting data', err.message);
        console.error(err);
      });
};

const readMilestones = () => {
  return (dispatch) =>
    getMilestones()
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((json) => {
        dispatch({
          type: 'SET_USER_MILESTONES',
          payload: json.pros_legacy_milestone,
        });
      })
      .catch((err) => {
        alert('Error when getting data', err.message);
        console.error(err);
      });
};
// fetchPublicUserData

const editUserBasics = (data, notify = null) => {
  return (dispatch) => {
    putUser(data)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((json) => {
        if(!json){
          throw new Error("json undefined");
        }
        if (json.errors) {
          throw new Error(JSON.stringify(json.errors));
        }
        if (notify && notify.state) {
          dispatch(updateNotify(notify));
        }
      })
      .catch((err) => {
        alert('Error when updating', err.message);
        console.error(err);
      });
  };
};

const updateBankAccount = (data, notify = null)=>{
  return (dispatch)=>{
    putBankAccount(data)
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`)
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined")
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      if (notify && notify.state) {
        dispatch(updateNotify(notify));
      }
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

const getPublicProfile = (username) => {
  return (dispatch) => {
    fetchPublicUserData(username)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((json) => {
        dispatch(addUserData(json));
      })
      .catch((err) => console.error(err));
  };
};

const getRatings = (username) => {
  return (dispatch) => {
    fetchRatings(username)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        dispatch(addRatings(json));
      })
      .catch((err) => console.error(err));
  };
};

const userDataLoading = (state) => {
  return {
    type: 'LOADING_USERDATA',
    payload: state,
  };
};

const editUser = (user, notify) => {
  return (dispatch) => {
    fetchPutUser(user)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((json) => {
        if (Object.keys(json).length === 0) {
          throw new Error('Empty JSON object returned')
        }
        const updateUser = Object.assign(
          {},
          {
            basics: json,
            work: json.work_experience,
            education: json.education,
            certifications: json.certifications,
            bank_account: json.bank_account,
            milestones: json.milestones,
          }
        );
        dispatch(addUserData(updateUser));
      })
      .catch((err) => {
        alert("Error when updating", err.message)
        console.error(err)
      });
    return notify != null && notify.state
      ? dispatch(updateNotify(notify))
      : null;
  };
};

const upsertWork = (data,notify = null)=>{
  return (dispatch)=>{
    postWorkExperiences(data)
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`)
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined")
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      if (notify && notify.state) {
        dispatch(updateNotify(notify));
      }
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

const upsertEducation = (data,notify = null)=>{
  return (dispatch)=>{
    postEducation(data)
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`)
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined")
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      if (notify && notify.state) {
        dispatch(updateNotify(notify));
      }
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

const upsertCertifications = (data,notify = null)=>{
  return (dispatch)=>{
    postCertifications(data)
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`);
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined")
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      if (notify && notify.state) {
        dispatch(updateNotify(notify));
      }
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

const completeProfile = (callback)=>{
  return (dispatch)=>{
    postCompletedProfile()
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`);
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined");
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      callback()
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

const completeDocumentsSubmission = (callback)=>{
  return (dispatch)=>{
    postCompletedDocumentSubmission()
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(`Status: ${res.status}`);
    })
    .then((json) => {
      if(!json){
        throw new Error("data undefined");
      }
      if (json.errors) {
        throw new Error(JSON.stringify(json.errors));
      }
      callback()
    })
    .catch((err) => {
      alert('Error when updating', err.message);
      console.error(err);
    });
  }
}

export {
  readUserData,
  readMilestones,
  editUserBasics,
  addUserData,
  getPublicProfile,
  userDataLoading,
  getRatings,
  editUser,
  updateBankAccount,
  upsertWork,
  upsertEducation,
  upsertCertifications,
  completeProfile,
  completeDocumentsSubmission
};
