// Container Component
import React, { Component } from "react";
import { connect } from "react-redux";

import ProfileForm from "./BasicInfo/ProfileForm";
import EditBankInfo from "./EditBankInfo";
import UserList from "./UserList";
// import Employment from './UserEmployment';

import Loading from "../Loading";
import { editUser } from "../../reduxState/actions/userData";

class UserInfoTab extends Component {
  submit = (values) => {
    let newUserData = Object.assign({},
      values.basics,
      values,
      {
        work_experience: values.work,
      });
    ["milestones", "basics", "work"].forEach((e) => delete newUserData[e]);
    this.props.editUser(newUserData, { state: true });
  };

  render() {
    let user_info_tab = <Loading />;
    const currentTab = this.props.match.params.profile_section;
    const headers = {
      work: "position",
      education: "degree",
      certifications: "name",
    };

    if (this.props.userData != null && this.props.config != null) {
      switch (currentTab) {
        case "basic_info": {
          return user_info_tab = (
            <ProfileForm
              availability_section={true}
              type={currentTab}
              onSubmit={this.submit}
              config={this.props.config}
              initialValues={this.props.userData}
            />
          );
        }
        case "bank_account":
          user_info_tab = <EditBankInfo />;
          break;
        case "work":
        case "education":
        case "certifications":
          user_info_tab = (
            <UserList
              onSubmit={this.submit}
              initialValues={this.props.userData}
              item_structure={
                this.props.config.profile.tabs.find(
                  (tab) => tab.key === currentTab
                ).item_structure
              }
              type={currentTab}
              header={headers[currentTab]}
            />
          );
          break;
        default:
          user_info_tab = null;
      }
      return (
        <section className="tab-content">
          <div className="tab-pane active">{user_info_tab}</div>
        </section>
      );
    } else {
      return <Loading />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    config: state.config,
  };
};

export default connect(mapStateToProps, { editUser })(UserInfoTab);
