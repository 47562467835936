import React, { Component } from "react";

import Item from "./Item";
import Loading from "../../Loading";

class List extends Component {
  render() {
    if (this.props.listData) {
      return (
        <div className="box-body">
          {this.props.listData.map((item) => (
            <Item
              img={item.img}
              placeholderImg={this.props.placeholderImg}
              title={item.title}
              subtitle={item.subtitle}
              subSubtitle={item.subSubtitle}
              description={item.description}
            />
          ))}
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default List;
