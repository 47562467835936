import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import qs from 'qs';

import Loading from './components/Loading';
import TopNavBarContainer from './components/Dashboard/TopNavBarContainer';
import SidebarContainer from './components/Dashboard/SidebarContainer';
import Notification from './components/Dashboard/Notification';
import ProfileContainer from './pages/Profile/ProfileContainer';
import ReviewsContainer from './pages/Reviews/ReviewsContainer';
import Settings from './pages/Settings/Settings';
import Reimbursement from './components/Reimbursement';
import RegisterContainer from './pages/Register/RegisterContainer';
import SetupContainer from './pages/Setup/Setup.container';
import DocumentsContainer from './pages/Documents/DocumentsContainer';
import Root from './pages/Root';

class App extends Component {
  static propTypes = {
    getConfig: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    userData: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
    dashboardUI: PropTypes.shape({
      navbar: PropTypes.bool,
    }).isRequired,
    readUserData: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    hideNavbar: PropTypes.func.isRequired,
    logging: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userData: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  componentDidMount() {
    this.props.getConfig();
    this.props.logging();
    this.handledashboardUI();
    const token = localStorage.getItem('access_token');
    if (token) {
      this.props.readUserData(token, this.props);
    }
  }

  handledashboardUI = () => {
    const { location } = this.props;
    const parsed = qs.parse(location.search.replace('?', ''));
    this.handleHideNavbar(parsed);
  };

  handleHideNavbar = (parsed) => {
    const { hideNavbar } = this.props;
    if (parsed.navbar != null) {
      hideNavbar(JSON.parse(parsed.navbar));
    }
  };

  onSetSidebarOpen = () => this.setState({ sidebarOpen: true });

  pushMenu = () => {
    // set false only
    const { body } = document;
    if (body.clientWidth < 769) {
      body.className = body.className.replace(' sidebar-open', '');
    }
  };

  render() {
    const { userData, dashboardUI, location } = this.props;
    const { sidebarOpen } = this.state;

    return userData ? (
      <div className="wrapper">
        <Notification />
        <TopNavBarContainer {...this.props} />
        <SidebarContainer
          {...this.props}
          open={sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
        />
        <div
          className="content-wrapper"
          onClick={this.pushMenu}
          onKeyPress={() => {}}
          style={{
            width: 'auto',
            paddingTop: dashboardUI.navbar ? '50px' : null,
          }}
          role="presentation"
        >
          <Switch location={location}>
            <Route
              path="/profile/:profile_section?"
              component={ProfileContainer}
            />
            <Route path="/reviews" component={ReviewsContainer} />
            <Route path="/settings" component={Settings} />
            <Route path="/reimbursement" component={Reimbursement} />
            {/* setup/upload must be before /setup */}
            <Route exact path="/setup/upload" component={DocumentsContainer} />
            <Route path="/setup" component={SetupContainer} />
            <Route path="/register" component={RegisterContainer} />
            <Route exact path="/documents" component={DocumentsContainer} />
            <Route exact path="/" component={Root} />
          </Switch>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default connect()(App);
