import React, { Component } from "react";


class ServerError extends Component {

    componentDidMount(){
        if(document.getElementsByClassName("content-wrapper").length > 0){
            document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor= "#87b1de"
        }
    }

    redirect = ()=>{
        this.props.history.goBack();
    }

    render() {
        return (
            <main className="content" style={{backgroundColor:"#87b1de",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <section className="row">
                    <div className="col-xs-12">
                        <div className="pending">
                            <h2>Oops! That can't be right</h2>
                            <br />
                            <p><button style={{color:"white"}} onClick={this.redirect}>Click <span class="btn">here</span> to try again</button></p>
                        </div>
                    </div>
                </section>
            </main>
        )
    };
};

export default ServerError;
