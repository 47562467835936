import { reduxForm } from 'redux-form';
import ListContainer from '../../../components/Input/List/ListContainer';

const RegisterWorkExperiences = reduxForm({
    form: 'workExperiences',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(ListContainer);

export default RegisterWorkExperiences;
