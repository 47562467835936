import React from 'react';

import CompletedProfile from "../../components/Setup/Step/Description/CompletedProfile";
import CompletedDocumentsSubmission from "../../components/Setup/Step/Description/CompletedDocumentsSubmission";
import CompletedFreelanceAgreement from "../../components/Setup/Step/Description/CompletedFreelanceAgreement";
import AwaitingConfirmation from '../../components/Setup/Step/Description/AwaitingConfirmation';
import AwaitingConfirmationSwabber from '../../components/Setup/Step/Description/AwaitingConfirmationSwabber';

export default {
  completed_profile: {
    title: "Setup Your JagaPro Profile",
    button: "Setup JagaPro Profile",
    status: "incomplete",
    content: <CompletedProfile />,
    type: "changeTab",
    url: "/register",
    index: 0
  },
  completed_documents_submission: {
    title: "Upload Supporting Documents ",
    button: "Upload Documents",
    status: "incomplete",
    content: <CompletedDocumentsSubmission/>,
    type: "changeTab",
    url: "/setup/upload",
    index: 1
  },
  completed_freelance_agreement: {
    title: "Sign Freelance Agreement",
    button: "Review Freelance Agreement",
    status: "incomplete",
    content: <CompletedFreelanceAgreement/>,
    type: "url",
    //Change the url to fill fields and fit the right vocation
    url: "",
    index: 2
  },
  awaiting_confirmation: {
    title: "Await Confirmation",
    content: <AwaitingConfirmation/>,
    status: "incomplete",
    index: 3
  },
  awaiting_confirmation_swabber: {
    title: "Await Confirmation",
    content: <AwaitingConfirmationSwabber/>,
    status: "incomplete",
    index: 4
  },
};