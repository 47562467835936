import React, { Component } from "react";
import { connect } from "react-redux";

// import Item from './Item';
import List from "./List";

import { updateNotify } from "../../../reduxState/actions/dashboardUI";

import { FieldArray } from "redux-form";

class ListContainer extends Component {
  handleOnSubmit = (e) => {
    e.preventDefault();
    if (!this.props.valid) {
      this.props.dispatch(
        updateNotify({
          state: true,
          type: "error",
          message: `Some required fields are not completed`,
        })
      );
    }
    this.props.handleSubmit(e);
  };

  render() {
    const {
      showOnly,
      type,
      header,
      item_structure,
      backButton,
      helper,
      goBack,
    } = this.props;
    const back = backButton ? (
      <button className="pill pull-left" type="button" onClick={goBack}>
        Back
      </button>
    ) : null;
    const update = showOnly ? null : (
      <button className="pill solid-blue pull-right" type="submit">
        {backButton ? "Next" : "Update"}
      </button>
    );

    return (
      <form className="box-body" onSubmit={this.handleOnSubmit}>
        {helper != null ? (
          <div
            style={{ padding: "1rem" }}
            dangerouslySetInnerHTML={{ __html: helper }}
          />
        ) : null}
        <FieldArray
          name={type}
          component={List}
          props={{ item_structure, header, type }}
        />
        <div className="box-footer mobile-footer">
          {back}
          {update}
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ config }) => {
  return {
    config,
  };
};

export default connect(mapStateToProps)(ListContainer);
