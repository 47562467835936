import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import { getStatus } from '../reduxState/api/userData';

const Root = (props) => {
  useEffect(() => {
    const callRedirect = redirect;
    callRedirect();
  }, []);

  const redirect = () => {
    getStatus()
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (
          !json ||
          !json.pros_legacy_nurse_by_pk ||
          !json.pros_legacy_nurse_by_pk.jagaproStatus
        ) {
          props.history.replace('/logout');
        }
        switch (json.pros_legacy_nurse_by_pk.jagaproStatus) {
          case 'requested':
          case 'pending':
            return props.history.replace('/setup');
          default:
            return props.history.replace('/profile/basic_info');
        }
      });
  };

  return <Loading />;
};

export default Root;
