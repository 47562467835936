import React from 'react';

const AwaitingConfirmation = () => (
  <div>
    <p>
      Congratulations! Your account is all set up with us! While we are
      verifying all the official documents that you have submitted,
    </p>
    <ol>
      <li>
        Give us a heads up via WhatsApp{' '}
        <a
          className="whatsapp"
          href="https://api.whatsapp.com/send?phone=6584996946"
        >
          <b>here</b>
        </a>
      </li>
      <li>
        Download the app. Do note that the app is only usable after we have
        verified the documents submitted.
        <br />
      </li>
    </ol>
    <div
      className="col-xs-12"
      style={{ display: 'flex', padding: '0px', paddingBottom: '15px' }}
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.jagapros"
        style={{ marginLeft: '-10px' }}
      >
        <img
          alt="Get it on Google Play"
          height="60"
          src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
        />
      </a>
      <a
        href="http://jaga.sg/pros/mobile"
        style={{
          marginTop: '10px',
          display: 'inline-block',
          overflow: 'hidden',
          background: `url(
            '//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg'
          )`,
          width: '135px',
          height: '40px',
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          border: '0px',
        }}
      ><span style={{display:"none"}}>apple</span>
      </a>
    </div>
    <div className="col-xs-12" style={{ display: 'flex', padding: '0px' }}>
      <p>
        Once you come down to meet us, grab your shirt/scrub. You will be then
        granted full access to the app and you are all ready to work!
      </p>
    </div>
  </div>
);

export default AwaitingConfirmation;
