import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProfileBox from "./profileBox";
// import Loading from './Loading';
import { connect } from 'react-redux'

//actions
import { logout } from '../../reduxState/actions/index';


class TopNavBar extends Component {

  state = {
    expand: false,
    display: "flex"
  }

  // Component LifeCycle

  componentDidMount() {
    if (!this.props.dashboardUI.hamburger || !this.props.dashboardUI.navbar || !this.props.dashboardUI.sidebar) {
      this.closeMenu();
    }
  }

  componentDidUpdate() {
    if (this.props.dashboardUI.sidebar != null && !this.props.dashboardUI.sidebar) {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    let body = document.body;
    if (body.className.search('sidebar-collapse') === -1) {
      body.className += ' sidebar-collapse';
    }
    body.className = body.className.replace(' sidebar-open', '');
  }

  pushMenu = () => {
    let body = document.body;
    if (body.className.indexOf('sidebar-collapse') === -1) {
      body.className += ' sidebar-collapse';
    } else {
      body.className = body.className.replace(' sidebar-collapse', '');
    }
    if (body.className.indexOf('sidebar-open') === -1) {
      body.className += ' sidebar-open';
    } else {
      body.className = body.className.replace(' sidebar-open', '');
    }
  }

  backHome = () => {
    let body = document.body;
    if (body.className.indexOf('sidebar-open') !== -1) {
      body.className = body.className.replace(' sidebar-open', '');
    }
  }

  handleLogOut = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    this.props.dispatch(logout(this.props));
    this.props.history.replace("/login");
  }

  expandProfileBox = () => {
    this.setState({ expand: true })
  }

  collapse = () => {
    this.setState({ expand: false });
  }

  render() {
    const { userData, dashboardUI, user } = this.props;

    if (userData && userData.basics && dashboardUI.navbar) {

      const { picture, gender } = userData.basics;
      const { joined_date } = user;
      const name = `${userData.basics.name} ${userData.basics.last_name}`

      let profileBox = null;

      const hamburger = dashboardUI.hamburger ? <button className="sidebar-toggle" data-toggle="offcanvas" onClick={this.pushMenu} /> : null;
      const pictureLink = picture != null && picture.length > 0 ? `https://process.filestackapi.com/${picture.substring(picture.lastIndexOf("/"))}` : `/assets/avatar/${gender === 'female' ? 'f' : 'm'}-adult.png`;

      if (this.state.expand) {

        profileBox = <ProfileBox
          clickOutside={this.collapse}
          pictureLink={pictureLink}
          joined_date={joined_date}
          name={name}
          onHandleLogOut={this.handleLogOut} />
      };

      return (

        <header className="main-header">

          <Link to="/" className="logo navbar-fixed-top" onClick={this.backHome} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span className="logo-lg"><img height="35" width="105" src="https://cdn.jaga-me.com/logos/v2/logo.white.large.png" alt={''} /></span>
          </Link>

          <nav className="navbar navbar-fixed-top">

            {hamburger}

            <span id="navbar-center-menu" className="jaga-me-logo">
              <Link to="/" onClick={this.backHome} ><img height="35" width="105" src="https://cdn.jaga-me.com/logos/v2/logo.white.large.png" alt={''} />
              </Link>
            </span>
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav" >
                <li className="dropdown user user-menu" >
                  <button className="dropdown-toggle" onClick={this.expandProfileBox}>
                    <img color="white" src={pictureLink} className="user-image" alt="User" />
                    <span className="hidden-xs">{name}</span>
                  </button>
                  {profileBox}
                </li>
              </ul>
            </div>
          </nav>
        </header>
      )
    } else {
      return null
    };
  };
};

export default connect()(TopNavBar);
