// export const toTitle = (title) => {
//     let newTitle = title.replace(/_/g, ' ');
//     newTitle.replace(/([A-Z])/g, " $1")
//     return `${newTitle.charAt(0).toUpperCase()}${newTitle.slice(1)}`
// }

export const isHtml = (str) => {
    return /<[a-z][\s\S]*>/i.test(str)
}

export const createMarkup = (html) => {
    return { __html: html }
}

export const toTitle = (str) => {
    // remove - and _
    let new_str = str.replace(/-|_/gi, ' ');
    return new_str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};