import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import {
  connectRouter,
  ConnectedRouter,
  routerMiddleware,
} from 'connected-react-router';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import thunk from 'redux-thunk';

import reducer from './reduxState/reducers/index';

import { isTokenValidAndNotExpired } from './utils/jwtHelper';

import AppContainer from './App.container';
import LoginContainer from './pages/Login/LoginContainer';
import PublicProfileContainer from './pages/PublicProfile/PublicProfile.container';
import SwabberZohoForm from './pages/SwabberZohoForm/SwabberZohoForm.screen';
import Loading from './components/Loading';

import NotFound from './components/Errors/404';
import ServerError from './components/Errors/502';
import Expired from './components/Errors/401';
import ErrorPage from './components/Errors/error';
import Unauthorised from './components/Errors/403';
import Maintenance from './components/Errors/503';
import Malformed from './components/Errors/400';

import './index.css';
import './App.css';
import './css/jagapro.css';
import './assets/css/icofont/css/icofont.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'react-quill/dist/quill.bubble.css';
import Logout from './pages/Logout';

const history = createBrowserHistory();
const locationHelper = locationHelperBuilder({});

const store = createStore(
  connectRouter(history)(reducer),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  compose(applyMiddleware(routerMiddleware(history), thunk))
);

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => isTokenValidAndNotExpired(state),
  authenticatingSelector: (state) => state.user.isLoading,
  AuthenticatingComponent: () => (
    <div className="content container login-background">
      <Loading type="login" />
    </div>
  ),
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => !isTokenValidAndNotExpired(state),
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

if (process.env.REACT_APP_BUGSNAG_KEY) {
  const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY);
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');

  ReactDOM.render(
    <ErrorBoundary>
     <Provider store={store}>
<ConnectedRouter history={history}>
  <Switch>
    <Route
      path="/public_profile/:jagapro_username"
      component={PublicProfileContainer}
    />
    <Route
      path="/login"
      component={userIsNotAuthenticated(LoginContainer)}
    />
    <Route path="/logout" component={Logout}/>
    <Route exact path="/400" component={Malformed} />
    <Route exact path="/401" component={Expired} />
    <Route exact path="/403" component={Unauthorised} />
    <Route exact path="/502" component={ServerError} />
    <Route exact path="/503" component={Maintenance} />
    <Route exact path="/404" component={NotFound} />
    <Route exact path="/error" component={ErrorPage} />
    <Route
      path="/:route?"
      component={userIsAuthenticated(AppContainer)}
    />
  </Switch>
</ConnectedRouter>
</Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          path="/public_profile/:jagapro_username"
          component={PublicProfileContainer}
        />
        <Route path="/home-swabber-zoho-form" component={SwabberZohoForm} />
        <Route
          path="/login"
          component={userIsNotAuthenticated(LoginContainer)}
        />
        <Route exact path="/400" component={Malformed} />
        <Route exact path="/401" component={Expired} />
        <Route exact path="/403" component={Unauthorised} />
        <Route exact path="/502" component={ServerError} />
        <Route exact path="/503" component={Maintenance} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/error" component={ErrorPage} />
        <Route
          path="/:route?"
          component={userIsAuthenticated(AppContainer)}
        />
      </Switch>
    </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
}
