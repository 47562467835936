// const initState = {
//   basics: null,
//   bank_account: null,
//   work_experiences: null,
//   education: null,
//   certifications: null,
//   milestones: null
// };

const userData = (state = null, action) => {
  switch (action.type) {
    case 'SET_USERDATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_USER_MILESTONES':
      return {
        ...state,
        milestones: action.payload
      } 
    case 'SET_USER_PICTURE':{
      return {
        ...state,
        basics: {
          ...state.basics,
          picture: action.payload.picture
        }
      }
    }
    case 'UPDATE_USER_BASICS':
      return {
        ...state,
        basics: action.payload,
      };
    case 'LOADING_USERDATA':
      return Object.assign({}, state, {
        basics: {
          ...state.basics,
          loading: action.payload,
        },
      });
    case 'UPDATE_BANK_ACCOUNT':
      return Object.assign({}, state, { bank_account: action.payload });
    case 'UPDATE_EDUCATION':
      return Object.assign({}, state, { education: action.payload });
    case 'UPDATE_WORK':
      return Object.assign({}, state, { work: action.payload });
    default:
      return state;
  }
};

export default userData;
