import React, { Component } from 'react';
import './Loading.css';

class Loading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      longWait: null,
      refresh: false
    }

    this.timeout1 = setTimeout(() => {
      this.setState({ longWait: "This may take a while..." })
    }, 2000)
    if (props.refresh) {
      this.timeout2 = setTimeout(() => {
        this.setState({
          longWait: "Click on",
          refresh: true
        })
      }, 7000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
    if (this.props.refresh) {
      clearTimeout(this.timeout2);
    }
  }

  handleClick = () => {
    window.location.reload();
  }

  render() {
    let color = this.props.type === "login" ? "white" : "black"
    let longWait = <p style={{ color: color }}>{this.state.longWait}</p>
    const height = JSON.stringify(window.innerHeight - 50) + "px"

    let message = this.props.type === "login" ? <h3 style={{ color: "white", fontWeight: "100", padding: "1rem" }}>Welcome, JagaPro</h3> : this.props.type === "case_view" ? <h3 style={{ color: "black", fontWeight: "100", padding: "1rem" }}>Loading cases</h3> : null

    let refresh = this.state.refresh ? <button className="pill solid-blue" onClick={this.handleClick}>Refresh</button> : null;

    return (
      <div style={{ display: 'table', height: height, width: '100%' }}>
        <div className="loading">
          <div className="loading-element">
            {message}
            {!this.state.refresh ? <figure className="loading-logo">
              <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={this.props.type === "login" ? "white" : "#87b1de"}>
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth="1">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite" />
                    </path>
                  </g>
                </g>
              </svg>
              <img src={`https://cdn.jaga-me.com/logos/v2/logo.square.${this.props.type === "login" ? "white" : "blue"}.png`} className="img-responsive" alt='spinner' />
              <figcaption style={{ color: this.props.type === "login" ? "white" : "#87b1de" }}>Give us a second...</figcaption>
            </figure> : null}
            {longWait}
            {refresh}
          </div>
        </div>
      </div>
    )
  }
}

export default Loading;
