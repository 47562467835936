import React, { Component } from 'react';
import { connect } from 'react-redux';


import { thunkNotify } from '../../reduxState/actions/dashboardUI';
import { completeDocumentsSubmission } from '../../reduxState/actions/userData';

import AddDocuments from './AddDocuments';

class DocumentsContainer extends Component {

  render() {
    return this.props.userData != null ?
      <AddDocuments {...this.props} /> : null;
  }
}

const getDashboardUI = dashboardUI => {
  return dashboardUI
};

const getConfig = config => {
  return config
};

const mapStateToProps = state => {
  return {
    dashboardUI: getDashboardUI(state.dashboardUI),
    userData: state.userData,
    config: getConfig(state.config)
  }
};

export default connect(mapStateToProps, { thunkNotify, completeDocumentsSubmission })(DocumentsContainer);