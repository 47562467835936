import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer.js';
import userData from './userData';
import reviews from './reviews';
import dashboardUI from './dashboardUI';
import register from './register';
import config from './config';
import ratings from './ratings';

let combinedReducer = combineReducers({
  form: formReducer,
  // routing: routerReducer,
  user: userReducer,
  userData,
  dashboardUI,
  register,
  config,
  ratings,
  reviews,
});

const indexReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export default indexReducer;
