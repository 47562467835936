import validator from 'validator';
import Moment from 'moment';

const extractContent = (s) => {
    let span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
};

export const validate = values => {
    const errors = {};

    // check for all empty fields
    Object.keys(values).map(valueKey => {
        if (!values[valueKey]) {
            errors[valueKey] = `${valueKey} is required`
        }
        return valueKey;
    })
    return errors;
}

export const required = value =>
    value ? undefined : 'value is required';

export const requiredSelect = value =>
    value && value.length > 0 ? undefined : 'Min one option';

export const requiredHTML = value => {
    const extractedContent = validator.trim(extractContent(value));
    return extractedContent.length > 0 ? undefined : 'value is required';
}

export const email = value =>
    validator.isEmail(value) ? undefined : 'value is not email';

export const minLengthThirty = (value) =>
    value && value.length > 30 ? undefined : 'value is too short';

export const minAge = (value) =>
    Moment(value).isBefore(Moment().subtract(18, 'years')) ? undefined : 'invalid date';

export const isPhoneNumber = value =>
    validator.isInt(value) && value.length >= 8 && value.length < 9 ? undefined : 'value is not a phone number';

export const isBankNumber = value =>
    validator.isInt(value) && value.length >= 9 && value.length <= 12 ? undefined : 'value is not a bank number';

export const isAlphabet = value =>
    validator.isAlpha(value) ? undefined : 'Name must be in alphabets';
