//TODO: tooo bloated requires code splitting and deprecation

//authentication actions 

export const login = (profile) => {
  return {
    type: 'USER_LOGGED_IN',
    payload: {
      email: profile.email,
      username: profile.username,
      picture: profile.picture,
      joined_date: profile.created_at

    }
  }
}

export const logout = (props) => {
  let logOutLink = "/login";
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('profile');
  props.history.push(logOutLink);
  return {
    type: 'USER_LOGGED_OUT'
  }
}

export const logging = () => {
  return {
    type: 'USER_LOGGING_IN'
  }
}

//authentication actions 

export const notifySuccess = state => {
  return {
    type: 'NOTIFY_SUCCESS',
    payload: state
  }
}

//Query Strings Requests START

export const hideNavbar = (status) => {
  return {
    type: 'HIDE_NAVBAR',
    payload: status
  }
}