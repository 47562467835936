import React from 'react';
import cx from 'classnames';
import { toTitle } from '../utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import { getValidityClassName, getValidity } from './editable';


class EditableQuill extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.input.value
        }
    }

    onChangeValue = (value) => {
        this.setState({ value })
        this.props.input.onChange(value)
    }

    handleFocus = () => {
        this.props.input.onFocus(this.state.value)
    }

    handleBlur = (e) => {
        this.props.input.onBlur(this.state.value)
    }

    render() {
        const {  label, placeholder, helper, meta } = this.props;

        return (
            <div className={cx(
                'form-group',
                getValidityClassName(meta)
            )}>
                <label>{toTitle(label)}</label>
                <div style={{ padding: "1rem" }} dangerouslySetInnerHTML={{ __html: helper }} />
                <ReactQuill
                    placeholder={placeholder}
                    value={this.state.value}
                    onChange={this.onChangeValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    theme='bubble'
                    className='profile-rte'
                />
                {getValidity(meta) ? <span style={{ color: "#dd4b39" }}>{meta.error}</span> : null}
            </div>
        )
    }
}

export default EditableQuill;