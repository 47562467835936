import decode from "jwt-decode";

export function isTokenValid(token) {
  try {
    const decoded = decode(token);
    return decoded
  } catch {
    return false
  }
}

export function getTokenExpirationDate(decoded) {
  try {
    if (!decoded.exp) {
      return null;
    }
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  } catch {
    return null
  }
}

export const isTokenExpired = (date) => {
  return date.valueOf() < new Date().valueOf();
};

export const isTokenValidAndNotExpired = (state) => {
  const token = localStorage.getItem("access_token")
  const decoded = isTokenValid(token)
  if(!decoded){
    return false
  }
  const date = getTokenExpirationDate(decoded);
  if(!date){
    return false
  }
  const result = isTokenExpired(date);

  return !result;
};
