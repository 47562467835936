import React, { Component } from "react";
import { toTitle, isHtml } from "../utils";
import Moment from "moment";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

// new React Select

import cx from "classnames";

export const getValidity = meta => {
  if (meta.active) {
    return false;
  }
  if (meta.touched && meta.invalid) {
    return true;
  }
  // if (meta.touched && meta.valid)
  //     return 'has-success';
};

export const getValidityClassName = meta => {
  if (meta.active) {
    return;
  }
  if (meta.touched && meta.invalid) {
    return "has-error";
  }
  // if (meta.touched && meta.valid)
  //     return 'has-success';
};

// for text, checkbox
export const customInput = props => {
  const {
    icon,
    input,
    label,
    type,
    /*placeholder,*/ supportingText,
    meta,
    helper
  } = props;
  const iconElement =
    typeof icon != "undefined" && icon.substring(0, 2) === "fa" ? (
      <div className="input-group-addon">
        <i className={icon} />
      </div>
    ) : null;

  return (
    <div className={cx("form-group", getValidityClassName(meta))}>
      <label>{toTitle(label)}</label>
      <div
        style={{
          color: "gray",
          maxWidth: "100%",
          marginBottom: "5px"
        }}
      >
        {helper}
      </div>
      <div className="input-group">
        {iconElement}
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={type}
          className="form-control"
          // onBlur={handleBlur}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
      </div>
      {getValidity(meta) ? (
        <span style={{ color: "#dd4b39" }}>{meta.error}</span>
      ) : null}
      {supportingText != null && isHtml(supportingText) ? (
        <div style={{ color: "gray", paddingTop:"0.5rem" }} dangerouslySetInnerHTML={{ __html: supportingText }} />
      ) : supportingText != null ? (
        <div style={{ color: "gray", paddingTop:"0.5rem" }}>{supportingText}</div>
      ) : null}
    </div>
  );
};

export class CustomSelect extends Component {
  customHandleChange = e => {
    e.preventDefault();
    this.props.handleChange(e.target.value);
  };

  customHandleBlur = e => {
    e.preventDefault();
    this.props.blur(e.target.value);
  };

  render() {
    const {
      label,
      options,
      input,
      icon,
      meta,
      handleChange,
      blur
    } = this.props;
    const iconElement =
      typeof icon != "undefined" &&
        icon != null &&
        icon.substring(0, 2) === "fa" ? (
        <div className="input-group-addon">
          <i className={icon} />
        </div>
      ) : null;
    return (
      <div className={cx("form-group", getValidityClassName(meta))}>
        {label ? <label>{toTitle(label)}</label> : null}
        <div className="input-group">
          {iconElement}
          <select
            className="form-control"
            value={input.value}
            onChange={handleChange ? this.customHandleChange : input.onChange}
            onBlur={blur ? this.customHandleBlur : input.onBlur}
            disabled={input.value !== "present" ? false : true}
          >
            {options
              ? options.map(option => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))
              : null}
          </select>
        </div>
        {getValidity(meta) ? (
          <span style={{ color: "#dd4b39" }}>{meta.error}</span>
        ) : null}

        {options &&
          options[1].description &&
          options.find(option => option.value === input.value) ? (
          <p style={{ paddingTop: "1rem" }}>
            {options.find(option => option.value === input.value).description}
          </p>
        ) : null}
      </div>
    );
  }
}

export class customDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      present: props.input.value === "present" ? true : false
    };
    this.thisDate = React.createRef();
  }

  handleClose = e =>
    this.props.input.onBlur(
      e
        ? Moment(e[0])
          .utc()
          .local()
          .toISOString()
        : ""
    );

  handleOpen = e => this.props.input.onFocus(
    e
      ? Moment(e[0])
        .utc()
        .local()
        .toISOString()
      : ""
  );

  onChange = e => {
    if (e.length > 0) {
      this.props.input.onChange(
        Moment(e[0])
          .utc()
          .local()
          .toISOString()
      )
    }
  }

  render() {
    const { icon, input, label, meta, helper } = this.props;
    const iconElement =
      typeof icon !== "undefined" && icon.substring(0, 2) !== "fa" ? (
        <span className="input-group-addon">{icon}</span>
      ) : typeof icon !== "undefined" ? (
        <span className="input-group-addon">
          <i className={icon} />
        </span>
      ) : null;
    const options = {
      altInput: true,
      allowInput: true,
      // dateFormat: "d-m-Y",
      dateFormat: "m-Y",
      defaultDate:
        input.value != null && input.value.length > 0
          ? Moment(input.value)
            .utc()
            .local()
            .toISOString()
          : ""
    };

    return (
      <div
        className={cx("form-group", getValidityClassName(meta))}
        key={this.props.input.name}
      >
        <label>{toTitle(label)}</label>
        <div className="input-group" ref={this.thisDate}>
          {iconElement}
          <Flatpickr
            disabled={this.state.present}
            onChange={this.onChange}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            options={options}
          />
        </div>
        <div
          style={{
            color: "gray",
            maxWidth: "100%",
            marginBottom: "5px"
          }}
        >
          {helper}
        </div>
        {getValidity(meta) ? (
          <span style={{ color: "#dd4b39" }}>{meta.error}</span>
        ) : null}
      </div>
    );
  }
}

export class customMultiSelect extends Component {
  state = {
    selected: []
  };

  structureData = data => {
    return data && data.value != null && data.value.length > 0
      ? data.value.map(lang => {
        return {
          label: lang.charAt(0).toUpperCase() + lang.slice(1),
          value: lang.toLowerCase()
        };
      })
      : [];
  };

  handleChange = e => {
    const selected = e.map(option => option.value);
    this.setState({ selected });
    return this.props.input.onChange(selected);
  };

  render() {
    const { input, label, options, supportingText, meta } = this.props;
    return (
      <div className={cx("form-group", getValidityClassName(meta))}>
        <label>{toTitle(label)}</label>
        <Select
          multi={true}
          value={this.structureData(input)}
          options={options}
          onChange={this.handleChange}
          onBlur={() => input.onBlur(this.state.selected)}
          onFocus={() => input.onFocus(this.state.selected)}
        />
        {getValidity(meta) ? (
          <span style={{ color: "#dd4b39" }}>{meta.error}</span>
        ) : null}
        {supportingText != null ? (
          <div style={{ color: "gray" }}>{supportingText}</div>
        ) : null}
      </div>
    );
  }
}

export const customTextArea = props => {
  const { input, label, meta } = props;

  return (
    <div className={cx("form-group", getValidityClassName(meta))}>
      <label>{toTitle(label)}</label>
      <textarea className="form-control" rows="4" {...input} />
    </div>
  );
};

export const years = () => {
  const currentYear = Number(Moment().format("YYYY"));
  let yearRange = [{ label: "Select Year", value: "" }];
  for (let i = currentYear + 10; i > currentYear - 30; i--) {
    yearRange.push({ label: i, value: i });
  }

  return yearRange;
};

const months = () => {
  let months_options = [{ label: "Select Month", value: "" }];

  Moment.monthsShort().map(month => {
    months_options.push({ label: month, value: month });
    return month;
  });

  return months_options;
};

export class CustomDateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value
    };
  }

  changeYear = newYear => {
    let date = this.state.value.length > 0 ? this.state.value : new Date();
    let value = Moment(date)
      .year(newYear)
      .utc()
      .local()
      .toISOString();
    this.setState({ value });
    this.props.input.onChange(value);
  };

  changeMonth = newMonth => {
    let date = this.state.value.length > 0 ? this.state.value : new Date();
    let value = Moment(date)
      .month(newMonth)
      .utc()
      .local()
      .toISOString();
    this.setState({ value });
    this.props.input.onChange(value);
  };

  blurYear = () => {
    this.props.input.onBlur(this.state.value);
  };

  blurMonth = () => {
    this.props.input.onBlur(this.state.value);
  };

  setPresent = event => {
    event.preventDefault();
    let value =
      this.state.value !== "present"
        ? "present"
        : Moment()
          .utc()
          .local()
          .toISOString();
    this.setState({ value });
    this.props.input.onChange(value);
  };

  render() {
    const { input, meta, label, supportingText } = this.props;
    const { value } = this.state;
    const currentlyWorking = value === "present";
    const yearValue = Object.assign({}, input, {
      value:
        !currentlyWorking && value.length > 0
          ? Number(Moment(value).format("YYYY"))
          : value
    });

    // value.length > 0 ?
    const monthValue = Object.assign({}, input, {
      value:
        !currentlyWorking && value.length > 0
          ? Moment(value).format("MMM")
          : value
    });

    return (
      <div className={cx("form-group", getValidityClassName(meta))}>
        <label>{toTitle(label)}</label>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CustomSelect
              {...this.props}
              input={monthValue}
              handleChange={this.changeMonth}
              blur={this.blurMonth}
              label={false}
              options={months()}
            />
            <CustomSelect
              {...this.props}
              input={yearValue}
              handleChange={this.changeYear}
              blur={this.blurYear}
              label={false}
              options={years()}
            />
          </div>
        {supportingText && <div style={{ color: "gray" }}>{supportingText}</div>}
      </div>
    );
  }
}

export const Email = props => {
  const {
    icon,
    input,
    label,
    type,
    /*placeholder,*/ supportingText,
    meta,
    helper
  } = props;
  const iconElement =
    typeof icon != "undefined" && icon.substring(0, 2) === "fa" ? (
      <div className="input-group-addon">
        <i className={icon} />
      </div>
    ) : null;

  return (
    <div className={cx("form-group", getValidityClassName(meta))}>
      <label>{toTitle(label)}</label>
      <div
        style={{
          color: "gray",
          maxWidth: "100%",
          marginBottom: "5px"
        }}
      >
        {helper}
      </div>
      <div className="input-group">
        {iconElement}
        <input
          {...input}
          autoFocus={props.autoFocus}
          type={type}
          className="form-control"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
          disabled
        />
      </div>
      {getValidity(meta) ? (
        <span style={{ color: "#dd4b39" }}>{meta.error}</span>
      ) : null}
      {supportingText != null && isHtml(supportingText) ? (
        <div dangerouslySetInnerHTML={{ __html: supportingText }} />
      ) : supportingText != null ? (
        <div style={{ color: "gray", paddingTop:"0.5rem" }}>{supportingText}</div>
      ) : null}
    </div>
  );
};

export { CustomDOB as customDOB } from "./CustomDOB.component";