import React, { Component } from "react";

//  Custom Components
import RegisterPanes from "./RegisterPanes";
//  actions

class Register extends Component {
  render() {
    const { tabs, currentTab } = this.props.register;
    const title = tabs.length > 0
      && tabs[currentTab]
      && tabs[currentTab].title
      ? tabs[currentTab].title : "Title";

    return (
      <div
        className="row"
        style={{ height: !this.props.dashboardUI.navbar ? "100%" : "" }}
      >
        <main className="register col-md-offset-2 col-lg-offset-3 col-xs-12 col-md-8 col-lg-6">
          <div
            className="nav-tabs-custom"
            style={{
              marginBottom: !this.props.dashboardUI.navbar ? "0px" : "20px",
              height: !this.props.dashboardUI.navbar ? "100%" : "",
            }}
          >
            <header className="nav nav-tabs">
              <span className="col-xs-12">
                <h4><b>{title}</b></h4>
                <div className="progress progress-xxs ">
                  <div
                    className="progress-bar progress-bar-blue"
                    style={{
                      width: `${((currentTab + 1) / tabs.length) * 100}%`,
                    }}
                  ></div>
                </div>
              </span>
            </header>

            <RegisterPanes {...this.props} />
          </div>
        </main>
      </div>
    );
  }
}

export default Register;
