import React, { Component } from 'react';
import { Plus } from 'react-feather';
import { createMarkup } from '../../../components/utils';
import Picker from '../../../components/FileStackPicker';
import Moment from 'moment';

class AddProfilePhoto extends Component {

  picker = new Picker();

  state = {
    loading: false
  }

  handleNext = (e) => {
    e.preventDefault();
    this.props.handleSubmit();
  }

  handleClick = () => {
    const { username, id } = this.props.userData.basics;
    const date = Moment().format("DDMMYY");

    const config = {
      s3_path: `/jagapros-assets/documents/${id}/${date}_${username}_profile.png`,
      filename: `${date}_${username}_profile.png`,
      transformations: {
        crop: {
          aspectRatio: 1 / 1,
          force: true
        },
        rotate: true
      },
      onUploadDone: result => {
        this.setState({ loading: true })
        this.props.updateProfile(result.filesUploaded[0].url);
      }
    };
    this.picker.set(config).open();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData && prevProps.userData.basics.picture !== this.props.userData.basics.picture) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { picture, gender } = this.props.userData.basics;
    const pictureHandle = picture ? picture.substring(picture.lastIndexOf("/")) : undefined
    const pictureLink = picture != null && picture.length > 0 ? picture : `/assets/avatar/${gender === 'female' ? 'f' : 'm'}-adult.png`;

    const profile = this.state.loading ? 
    <figure className="loading-logo">
      <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={this.props.type === "login" ? "white" : "#87b1de"}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="1">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite" />
            </path>
          </g>
        </g>
      </svg>
      <img src={`https://cdn.jaga-me.com/logos/v2/logo.square.${this.props.type === "login" ? "white" : "blue"}.png`} className="img-responsive" alt='spinner' />
    </figure> : 
    this.props.config.fields === 'profilePhoto' && picture !== null && picture.length > 0 && pictureHandle !== 'female.png' ?
      <img
        role="button"
        id='profile_img'
        className="profile-photo img-responsive img-circle"
        src={pictureLink}
        onClick={this.handleClick}
        style={{ margin: '0px' }}
        alt="User Profile" /> :
      <div
        onClick={this.handleClick}
        style={{
          height: "165px",
          width: "165px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          border: "dashed 5px #503fb1"
        }}>
        <Plus
          style={{
            color: '#503fb1',
            height: "70px"
          }}
        />
      </div>

    return (
      <div className="box-body centre" style={{
        height: '87%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <div>
          <div className='row centre' style={{ justifyContent: 'center' }}>
            {profile}
          </div>
          <div className='row centre' style={{ justifyContent: 'center', padding: '2rem' }}>
            {<div dangerouslySetInnerHTML={createMarkup(this.props.config.message)} />}
          </div>
        </div>
        <div className='box-footer mobile-footer' style={{ width: "100%" }}>
          <button className='pill solid-blue pull-right' onClick={this.handleNext}>Next</button>
        </div>
      </div>
    )
  }
}

export default AddProfilePhoto;
