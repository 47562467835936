import React from 'react';

const LanguagesListStyle = {
    display: "flex",
    flexWrap: "wrap"
}

const LanguagesList = ({ input }) => {
    if (input.length > 0) {
        let displayInput = typeof input != "string" ? [...input] : [...[input]];
        return (
            <section style={LanguagesListStyle}>
                {displayInput.map((lang, i) => {
                    return (<span
                        key={`lang ${i}`}
                        className='lang'
                    >{lang.charAt(0).toUpperCase()}{lang.slice(1)}</span>)
                })}
            </section>
        )
    } else {
        return (
            <p>spoken language not specified</p>
        )
    }
}

export default LanguagesList;