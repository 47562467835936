import React, { Component } from 'react';
import { Field } from 'redux-form';

import { customInput, CustomSelect } from '../../../components/Input/editable';
import { required, requiredSelect, isBankNumber } from '../../../components/validation/validate';

//  actions
import { updateNotify } from '../../../reduxState/actions/dashboardUI';

class BankInfo extends Component {

  handleOnSubmit = (e) => {
    e.preventDefault();
    if (!this.props.valid) {
      this.props.dispatch(updateNotify({ state: true, type: "error", message: `Some required fields are not completed` }));
    }
    this.props.handleSubmit(e);
  }

  render() {
    const { structure, backButton, goBack } = this.props;
    const back = backButton ? <button className='pill pull-left' type='button' onClick={goBack}>Back</button> : null;

    return (
      <form className="box-body" style={{ marginBottom: '70px' }} onSubmit={this.handleOnSubmit}>
        {structure.map(field => {
          switch (field.type) {
            case ('text'):
              return <Field
                key={field.name}
                name={field.name}
                type="text"
                label={field.label != null ? field.label : field.name}
                icon={field.icon}
                supportingText={field.supporting_text}
                component={customInput}
                validate={field.name === 'account_number' ? [required, isBankNumber] : [required]}
              />
            case ('select'):
              return <Field
                icon={field.icon != null ? field.icon : null}
                key={field.name}
                label={field.label != null ? field.label : field.name}
                name={field.name}
                options={field.options}
                component={CustomSelect}
                validate={[required, requiredSelect]}
              />
            default:
              return <Field
                key={field.name}
                name={field.name}
                type={field.type}
                label={field.label != null ? field.label : field.name}
                icon={field.icon}
                supportingText={field.supporting_text}
                component={customInput}
                validate={[required]}
              />
          }
        })}
        <div className='box-footer mobile-footer'>
          {back}
          <button className='pill solid-blue pull-right' type="submit">{backButton ? 'Next' : 'Update'}</button>
        </div>
      </form>
    )
  }
};

export default BankInfo;