const initState = {
    tabs: [],
    currentTab: 0
}

const register = (state = initState, action) => {
    switch (action.type) {
        case "LOAD_REGISTER_TABS":
            return Object.assign({}, state, { tabs: action.payload })
        case "CHANGE_REGISTER_TAB":
            return Object.assign({}, state, { currentTab: action.payload })
        default:
            return state
    }
}

export default register;