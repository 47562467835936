const init = {
    navbar: true,
    journals: false,
    hamburger: true,
    redirect: '/',
    notification: false,
    sidebar:null
}

const dashboardUI = (state = init, action) => {
    switch (action.type) {
        case 'HIDE_NAVBAR':
            return Object.assign({}, state, { navbar: action.payload })
        case 'HIDE_JOURNALS':
            return Object.assign({}, state, { journals: action.payload })
        case 'HIDE_HAMBURGER':
            return Object.assign({}, state, { hamburger: action.payload })
        case 'ADD_REDIRECT':
            return Object.assign({}, state, { redirect: action.payload })
        case 'UPDATE_NOTIFY':
            return Object.assign({}, state, { notification: action.payload })
        case 'UPDATE_SIDEBAR':
            return Object.assign({}, state, { sidebar: action.payload })
        default:
            return state
    }
}

export default dashboardUI;