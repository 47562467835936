export const capitalizeFirstLetter = (word) => {
  return `${word[0].toUpperCase()}${word.slice(1)}`;
};

export const capitalizeAllFirstLetter = (string) => {
  return string.split(" ").map(capitalizeFirstLetter).join(" ");
};

export const isHtml = (str) => {
  return /<[a-z][\s\S]*>/i.test(str);
};

export const createMarkup = (html) => {
  return { __html: html };
};
