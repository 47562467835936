const initialState = {
    data: null,
    isLoading: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_LOGGING_IN':
            return { data: null, isLoading: true }
        case 'USER_LOGGED_IN':
            return { data: action.payload, isLoading: false }
        case 'USER_LOGGED_OUT':
        return initialState
        default:
            return state
    };
};

export default userReducer;