import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Creatable } from 'react-select';
import cx from 'classnames';
import { toTitle } from '../utils';

import { getValidityClassName } from './editable';

class customCreatable extends Component {

    handleChange = (input) => {
        this.props.input.onChange(input != null && input.value ? input.value : "")
    }

    render() {
        const { input, label, options, supportingText, meta } = this.props;
        return (
            <div className={cx(
                'form-group',
                getValidityClassName(meta)
            )}>
                <label>{toTitle(label)}</label>
                <Creatable
                    multi={false}
                    value={input != null && input.value != null ? {
                        label: input.value,
                        value: input.value
                    } : ""}
                    isClearable
                    options={options.map(org => {
                        return {
                            label: org.full_name,
                            value: org.full_name
                        }
                    })}
                    onChange={this.handleChange}
                />
                {supportingText != null ? <div style={{ color: 'gray' }}>{supportingText}</div> : null}
            </div>
        )
    }
}

const getOrgLogos = org_logos => org_logos

const mapStateToProps = state => {
    return {
        options: getOrgLogos(state.config.org_logos)
    }
}

export default connect(mapStateToProps)(customCreatable);