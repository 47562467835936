import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Unauthorised extends Component {

    componentDidMount(){
        if(document.getElementsByClassName("content-wrapper").length > 0){
            document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor= "#87b1de"
        }
    }

    redirect = () => {
        this.props.history.replace("/login");
    }

    render() {
        let subtitle;

        subtitle = this.props.message != null ? <p style={{color:"white"}}>{this.props.message }</p>: <p><button style={{color:"white"}} onClick={this.redirect}>Click <span className="btn">here</span> to login</button></p>

        return (
            <main className="content" style={{backgroundColor:"#87b1de",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <section className="row">
                    <div className="col-xs-12">
                        <div className="pending">
                            <h2>Unauthorised access</h2>
                            <br />
                            {subtitle}

                        </div>
                    </div>
                </section>
            </main>
        )
    }
}


export default Unauthorised;
