import React, { Component } from "react";

import { Accordion, AccordionItem } from "react-sanfona";
import { connect } from "react-redux";
import SetupItem from "../../components/Setup/SetupItem";

import { hideNavbar } from "../../reduxState/actions/dashboardUI";
import { logout } from "../../reduxState/actions/index";

class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = props.onboardingStages;
  }

  componentDidMount() {
    this.props.dispatch(hideNavbar(false));
    this.buildOnboardingStagesData(this.props);
  }

  shouldComponentUpdate(newProps) {
    if (
      JSON.stringify(newProps.userData.milestones) !==
      JSON.stringify(this.props.userData.milestones)
    ) {
      this.buildOnboardingStagesData(newProps);
    }
    return true;
  }

  handleClick = (status, stage) => {
    //  if not current no redirecting and actions for the button
    if (status !== "current" || typeof stage.type === "undefined") {
      return;
    }
    switch (stage.type) {
      case "changeTab":
        this.props.history.push(stage.url);
        break;
      case "url":
        window.location.href = stage.url;
        break;
      default:
        break;
    }
  };

  handleLogout = () => {
    this.props.dispatch(logout(this.props));
  };

  buildOnboardingStagesData = (props) => {
    if (typeof props !== "undefined" && typeof props.userData === "object") {
      const {
        onboardingStages,
        userData: { milestones },
      } = props;

      for (
        let loopIndex = 0;
        loopIndex < Object.keys(onboardingStages).length;
        loopIndex++
      ) {
        let milestoneKey = Object.keys(onboardingStages)[loopIndex];

        if (milestones.some(milestone=>milestone.name  === milestoneKey)) {
          this.setState({
            [milestoneKey]: Object.assign({}, onboardingStages[milestoneKey], {
              status: "completed",
            }),
          });
        } else {
          this.setState({
            [milestoneKey]: Object.assign({}, onboardingStages[milestoneKey], {
              status: "current",
            }),
          });
          break;
        }
      }
    }
  };

  render() {
    const { onboardingStages } = this.props;
    const lastIndex = Object.keys(onboardingStages).length;

    return (
      <div className="row">
        <main
          className="wizard col-md-offset-1 col-lg-offset-2 col-xs-12 col-md-10 col-lg-8"
          style={{ height: !this.props.dashboardUI.navbar ? "100%" : "" }}
        >
          <div
            className="nav-tabs-custom"
            style={{
              marginBottom: !this.props.dashboardUI.navbar ? "0px" : "20px",
            }}
          >
            <header className="nav nav-tabs with-background jaga-techy" />

            <div className="box-body more-padding" style={styles.bodyContainer}>
              <h3 style={{ fontWeight: "900" }}>You’re Almost a JagaPro!</h3>
              <p style={{ paddingBottom: "1rem" }}>
                Just 3 steps and you can start taking cases!
              </p>

              <Accordion style={{ padding: "10px" }}>
                {Object.keys(onboardingStages)
                  .sort(
                    (a, b) =>
                      onboardingStages[a].index - onboardingStages[b].index
                  )
                  .map((milestoneKey, index) => {
                    const stage = onboardingStages[milestoneKey];
                    const { title, content, button } = stage;
                    const { status } = this.state[milestoneKey];
                    const stageClassNames = `onboarding-stage ${status}`;

                    return (
                      <AccordionItem
                        key={`accordion${milestoneKey}`}
                        number={index}
                        className="row"
                        status={status}
                        titleClassName={`setup-title ${status} ${index}`}
                        title={title}
                        expanded={status === "current"}
                        disabled={status !== "current"}
                        rootTag={SetupItem}
                      >
                        <div
                          key={milestoneKey}
                          className={stageClassNames}
                          // making props accessible to SetupItem 
                          status={status}
                          index={index}
                          lastindex={lastIndex}
                        >
                          <div className="row">
                            <div className="col-xs-12">
                              {content}
                            </div>
                            {index !==
                              Object.keys(onboardingStages).length - 1 ? (
                              <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5">
                                <div
                                  className="pill"
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                    textAlign: "left",
                                  }}
                                  onClick={() => {
                                    this.handleClick(status, stage);
                                  }}
                                >
                                  {button}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </AccordionItem>
                    );
                  })}
              </Accordion>

              <div className="row others">
                <div className="col-xs-12 centre">
                  <span className="whatsapp" style={{ paddingBottom: "1rem" }}>
                    Need help?{" "}
                    <a
                      className="whatsapp"
                      href="https://api.whatsapp.com/send?phone=6584996946"
                    >
                      <b>WhatsApp us</b>
                    </a>
                  </span>
                </div>
                <div className="col-xs-12 centre">
                  <p>© 2022 • Jaga-Me Pte Ltd</p>
                </div>
                <div
                  className="col-xs-12 centre logout"
                  onClick={this.handleLogout}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const styles = {
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
  },
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(Wizard);
