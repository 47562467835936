import React from 'react';

const CompletedDocumentsSubmission = () => (
  <ol style={{ paddingLeft: '20px', marginBottom: '2rem' }}>
    <li>NRIC (front and back)</li>
    <li>*For nurses only* BCLS certificate</li>
    <li>*For nurses only* SNB license</li>
    <li>*For nurses only* Highest Education Cert</li>
    <li>*For care aides only* Caregiving certification (e.g. HMI)</li>
    <li>
      Bank Statement (It must show the bank name, your name and account number)
    </li>
    <li>Other relevant certifications</li>
  </ol>
);

export default CompletedDocumentsSubmission;
