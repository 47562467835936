import { connect } from 'react-redux';
import TopNavBar from './TopNavBar';


const getUser = (user) => {
    return user
}

const readUserData = (userData) => {
    return userData
}

const getdashboardUI = dashboardUI =>{
    return dashboardUI
}

const mapStatetoProps = state => {

    return {
        user: getUser(state.user),
        userData: readUserData(state.userData),
        dashboardUI: getdashboardUI(state.dashboardUI)
    }
}

const TopNavBarContainer = connect(mapStatetoProps)(TopNavBar)

export default TopNavBarContainer;