import React, { Component } from "react";
import { connect } from 'react-redux';

import Auth from '../../components/Auth';
import Loading from '../../components/Loading'
import { readUserData } from '../../reduxState/actions/userData';

class Login extends Component {
  lock = new Auth();

  componentDidMount() {
    this.lock.handleAuthentication(this.props);
  }

  login = () => {
    this.lock.login(this.props);
  };

  render() {
    return (
      <div className="content container login-background">
        <Loading type={"login"} />
      </div>
    )
  };
};

const mapStatetoProps = state => {
  return {
    user: state.user,
    userData: state.userData,
    dashboardUI: state.dashboardUI
  }
}

export default connect(mapStatetoProps, { readUserData })(Login);