import { reduxForm } from 'redux-form';
import ListContainer from '../../../components/Input/List/ListContainer';

const RegisterEducation = reduxForm({
    form: 'education',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(ListContainer);

export default RegisterEducation;
