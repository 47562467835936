import React, { Component } from "react";
import NavLink from "./NavLink";
import { connect } from "react-redux";
import { logout } from "../../reduxState/actions/index";
import * as Icon from "react-feather";

const sidebar_options = [
  {
    name: "Profile",
    url: "profile",
    icon: "user-circle-o",
    new: false,
  },
  {
    name: "Reviews",
    url: "reviews",
    icon: "star",
    new: false,
  },
  {
    name: "Settings",
    url: "settings",
    icon: "cog",
    new: false,
  },
];

class Sidebar extends Component {

  closeMobileSideBar = () => {
    var body = document.body;
    body.className = body.className.replace(" sidebar-open", "");
  };

  handleSidebarClicked = () => {
    this.closeMobileSideBar();
  };

  logout = () => {
    this.props.dispatch(logout(this.props));
  };

  render() {
    let newBadge = (
      <span className="pull-right-container">
        <small className="label pull-right bg-green">new</small>
      </span>
    );

    let sideBarOptions = [];
    sidebar_options.map((option) => {
      let FeatherIcon;
      if (option.featherIcon != null) {
        FeatherIcon = Icon[`${option.featherIcon}`];
      }

      sideBarOptions.push(
        <li
          key={option.url}
          id={`sidebar-${option.url}`}
          onClick={this.handleSidebarClicked}
        >
          <NavLink
            to={`/${option.url}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            {option.icon != null ? (
              <i className={`fa fa-${option.icon} fa-lg`} />
            ) : null}
            {option.featherIcon != null ? <FeatherIcon size={20} /> : null}
            <span className="sidebar-tab-text">{option.name}</span>
            {option.new ? newBadge : null}
          </NavLink>
        </li>
      );
      return option;
    });

    if (this.props.user != null) {
      return (
        <aside className="main-sidebar">
          <div className="sidebar">
            <ul className="nav sidebar-menu">
              {sideBarOptions}
              <li style={{ marginTop: "1rem" }}>
                <NavLink to="#" onClick={this.logout}>
                  <i className="fa fa-sign-out  fa-lg" />
                  <span className="sidebar-tab-text">Log Out</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </aside>
      );
    } else {
      return null;
    }
  }
}

export default connect()(Sidebar);
