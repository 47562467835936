import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import { logout } from '../reduxState/actions/index';
import { connect } from "react-redux";

const Logout = (props) => {
  useEffect(() => {
    const callRedirect = redirect;
    callRedirect();
  }, []);

  const redirect = () => {
    localStorage.clear();
    props.dispatch(logout(props));
    props.history.replace("/login");
  };

  return <Loading />;
};

export default connect()(Logout);
