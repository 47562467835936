import React, { Component } from "react";

// gender img

export default class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: props.img,
    };
  }

  handleError = () => {
    this.setState({
      img: `/assets/avatar/${
        this.props.gender === "female" ? "f" : "m"
      }-adult.png`,
    });
  };

  render() {
    return (
      <img
        className={`profile-sm-photo img-responsive img-circle ${this.props.className && this.props.className}`}
        style={{ margin: "0px" }}
        src={
          this.state.img !== undefined
            ? this.state.img
            : "/assets/avatar/user-blue.png"
        }
        onError={this.handleError}
        alt="User Profile"
      />
    );
  }
}
