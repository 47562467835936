import React, { Component } from 'react';


class NotFound extends Component {

    componentDidMount(){
        if(document.getElementsByClassName("content-wrapper").length > 0){
            document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor= "#87b1de"
        }
    }

    redirect = () => {
        this.props.history.goBack();
    }

    componentWillUnmount(){
        document.getElementsByClassName("content-wrapper")["0"].style.backgroundColor= ""
    }

    render() {
        return (
            <main className="content" style={{backgroundColor:"#87b1de",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <section className="row">
                    <div className="col-xs-12">
                        <div className="pending">
                            <h2>Yikes! Page doesn't seem to be found!</h2>
                            <br />
                            <p><button style={{color:"white"}} onClick={this.redirect}>Click <span className="btn">here</span> to go back</button></p>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}


export default NotFound;
