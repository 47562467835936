import React, { Component } from "react";

class Maintenance extends Component {

    redirect = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <main className="content" style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='container' style={{    height: "100%"}}>
            <div className='row'  style={{    height: "100%"}}>
            <section className={`col-sm-6 col-xs-12`} 
               style={{
                // position:"absolute",
                // left:0,
                height: "100%",
                backgroundSize: 'cover',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
            }}>
            <h1 style={{
                fontSize:"9rem"
            }}>503</h1>
            <p>We are currently conducting Maintenance. Please check back later!</p>

                </section>
                <section className={`col-sm-6 col-xs-12`}
                style={{
                    // position:"absolute",
                    // right:0,
                    height: "100%",
                    backgroundImage: "url(http://my.jaga-me.com/svg/503.svg)",
                    backgroundSize: 'cover'
                }}
                >
                </section>
                </div>
                </div>
        
            </main>
        )
    };
};

export default Maintenance;
