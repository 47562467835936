export const hideHamburger = data=>{
    return {
        type:"HIDE_HAMBURGER",
        payload:data
    }
};

export const hideNavbar = status =>{
    return {
        type: 'HIDE_NAVBAR',
        payload: status
    }
};

export const hideJournals = status =>{
    return {
        type: 'HIDE_JOURNALS',
        payload: status
    }
};

export const addRedirect = redirect => {
    return {
        type: 'ADD_REDIRECT',
        payload: redirect
    }
}

export const updateNotify = state => {
    return {
        type: 'UPDATE_NOTIFY',
        payload:state
    }
}

export const hideSideBar = state => {
    return {
        type: 'UPDATE_SIDEBAR',
        payload:state
    }
}

export const thunkNotify = (data) =>{
    return (dispatch) =>{
        dispatch(updateNotify(data));
    };
};