import React, { Component } from 'react';

class RegisterCompletion extends Component {
  handleNext = () => {
    this.props.onSubmit();
  };

  render() {
    const { button_text, header } = this.props.config;
    const pictureLink = this.props.picture
      ? `https://process.filestackapi.com/${this.props.picture.substring(
          this.props.picture.lastIndexOf('/')
        )}`
      : `/assets/avatar/${
          this.props.gender === 'female' ? 'f' : 'm'
        }-adult.png`;

    return (
      <div
        className="box-body centre"
        style={{
          height: '87%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          <div className="row centre" style={{ justifyContent: 'center' }}>
            <img
              role="button"
              id="profile_img"
              className="profile-photo img-responsive img-circle"
              src={pictureLink}
              onClick={this.handleClick}
              style={{ margin: '0px', cursor: 'default' }}
              alt="User Profile"
            />
          </div>
          <div
            className="row centre"
            style={{
              justifyContent: 'center',
              padding: '2rem',
              flexDirection: 'column',
            }}
          >
            <p style={{ fontSize: '24px' }}>{header}</p>
            <div>Let’s get moving to the next step!</div>
          </div>

          <div
            className="box-footer mobile-footer centre"
            style={{ width: '100%' }}
          >
            <button className="pill solid-blue" onClick={this.handleNext}>
              {button_text}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterCompletion;
