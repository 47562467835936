import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loading from '../../components/Loading';

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reset: false
    }
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick() {
    const url = "https://jaga-me.au.auth0.com/dbconnections/change_password";
    const options = {
      headers: {
        "Content-type": "application/json"
      },
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        "client_id": "b7zXrqP20dfdMtNhDq16NYqbXhpDHs7v",
        "email": this.props.userData.basics.email,
        "connection": "Username-Password-Authentication"
      })
    }
    fetch(url, options)
      .then((res) => {
        return res.text();
      })
      .then((json) => {
        return this.setState({ reset: true })
      })
      .catch(err => console.error(err));
  };

  render() {
    if (this.props.userData != null) {
      let resetMessage = this.state.reset ? <span style={{ color: "green" }}>Rest Password Email has been sent!</span> : null;
      return (
        <main className="content">
          <div className="row justify-content-md-center">
            <div className="col-md-12">
              <div className="box box-danger">
                <div className="box-body">
                  <h4><i className="fa fa-exclamation-triangle"></i> Caution - <small>Kindly approach the following actions below carefully.</small></h4>

                  <ul className="nav nav-pills nav-stacked">
                    <li>
                      <h5>Reset Password</h5>
                      <p>
                        <button className="btn btn-xs btn-warning" onClick={this.handleClick}><b>Reset My Password</b></button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <small>An password reset link will be sent to your email. Please follow the instructions in the email.</small>
                      </p>
                    </li>

                  </ul>
                  {resetMessage}
                </div>
              </div>
            </div>
          </div>
        </main>
      )
    } else {
      return <Loading />
    }
  }
};

const mapStateToProps = state => {
  return {
    userData: state.userData
  }
};
export default connect(mapStateToProps)(Settings);