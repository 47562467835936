import React, { Component } from "react";
import Picker from "../../components/FileStackPicker";
import Moment from "moment";
import vocationConfig from "../../config/setup/vocations.json";
import { selectVocation } from "../../utils/jagapro.utils";

const DocumentField = ({
  label,
  upload,
  name,
  filename,
  handle,
  isValid,
  remove,
  onRemove,
}) => {
  return (
    <div
      className={`form-group ${
        isValid || isValid === undefined ? "" : "has-error"
      }`}
      style={{ padding: "1rem" }}
    >
      <label style={{ paddingBottom: "1rem" }}>{label}</label>
      <div
        className="input-group"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: handle ? "space-between" : "none",
        }}
      >
        <button
          className="pill solid-blue pull-left"
          type="button"
          onClick={() => upload(name)}
        >
          Choose File
        </button>
        <span style={{ paddingLeft: "1rem", fontSize: "12px" }}>
          {filename ? filename : "No file chosen"}
        </span>
        {handle ? (
          <img
            src={`https://cdn.filestackcontent.com/pjpg=q:20/resize=h:40,w:40/${handle}`}
            alt={""}
          />
        ) : remove ? (
          <button
            className="pill solid-blue pull-left"
            type="button"
            onClick={() => onRemove(name)}
          >
            Remove
          </button>
        ) : null}
      </div>
    </div>
  );
};

let index = 1;

class AddDocuments extends Component {
  picker = new Picker();

  state = {
    additionalDocuments: [{ name: `document_1`, label: `New Document #1` }],
    step: 0,
  };

  handleUpload = (name) => {
    const { username, gdrive_folder_id } = this.props.userData;
    const date = Moment().format("DDMMYY");

    // gdrive_id_ONBOARDING_filename

    const uploadConfig = {
      s3_path: `/onboarding/${gdrive_folder_id}_ONBOARDING_${date}_${username}_${name}`,
      filename: `${gdrive_folder_id}_ONBOARDING_${date}_${username}_${name}`,
      fromSources: ["local_file_system"],
      transformations: { circle: false },
      onUploadDone: (result) => {
        this.setState({
          [name]: {
            filename: result.filesUploaded[0].originalPath,
            handle: result.filesUploaded[0].handle,
            isValid: true,
          },
        });
      },
    };

    this.picker.set(uploadConfig).open();
  };

  handleNext = (role) => {
    // validating results
    const validity = vocationConfig[role]
      .documents.filter((document) => document.required)
      .map(({ name }) => {
        if (this.state[name] && this.state[name].isValid) {
          return true;
        } else {
          this.setState({ [name]: { isValid: false } });
          return false;
        }
      });

    if (validity.find((item) => item === false) === undefined) {
      this.setState({ step: this.state.step + 1 });
    } else {
      // this.setState({ step: this.state.step + 1 })
      this.props.thunkNotify({
        state: true,
        type: "error",
        message: `Please submit all required documents`,
      });
    }
  };

  handleSubmit = () => {
    this.props.completeDocumentsSubmission(()=>{
      this.props.history.push("/setup");
    });
  };

  handleBack = () => {
    this.setState({ step: this.state.step - 1 });
  };

  handleAdd = () => {
    // const index = this.state.additionalDocuments.length + 1
    index = index + 1;
    const additionalDocuments = [
      ...this.state.additionalDocuments,
      { name: `document_${index}`, label: `New Document #${index}` },
    ];
    this.setState({ additionalDocuments });
  };

  handleRemove = (name) => {
    const additionalDocuments = [
      ...this.state.additionalDocuments.filter((doc) => doc.name !== name),
    ];
    this.setState({ additionalDocuments });
  };

  render() {
    const { vocations } = this.props.userData;

    let role = selectVocation(vocations);

    return (
      <div className="row">
        <main className="register col-md-offset-2 col-lg-offset-3 col-xs-12 col-md-8 col-lg-6">
          <div className="nav-tabs-custom">
            <header className="nav nav-tabs">
              <span className="col-xs-12">
                <h3>Upload Supporting Documents</h3>
              </span>
            </header>

            <div className="register-content">
              <div
                className="box-body"
                style={{
                  height: "87%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {this.state.step === 0
                  ? vocationConfig[role]
                      .documents.map(({ name, label }) => {
                        const item = this.state[name];
                        return (
                          <DocumentField
                            key={name}
                            label={label}
                            name={name}
                            filename={item ? item.filename : undefined}
                            handle={item ? item.handle : undefined}
                            upload={this.handleUpload}
                            isValid={
                              item && item.isValid !== null
                                ? item.isValid
                                : undefined
                            }
                          />
                        );
                      })
                  : this.state.additionalDocuments.map(({ name, label }) => {
                      const item = this.state[name];
                      return (
                        <DocumentField
                          key={name}
                          label={label}
                          name={name}
                          filename={item ? item.filename : undefined}
                          handle={item ? item.handle : undefined}
                          upload={this.handleUpload}
                          valid={
                            item && item.isValid ? item.isValid : undefined
                          }
                          remove={true}
                          onRemove={this.handleRemove}
                        />
                      );
                    })}

                {this.state.step === 0 ? null : (
                  <button onClick={this.handleAdd}>
                    <h4>+ Add</h4>
                  </button>
                )}
              </div>

              <div className="box-footer mobile-footer">
                {this.state.step > 0 ? (
                  <button
                    className="pill solid-blue pull-left"
                    onClick={this.handleBack}
                  >
                    Back
                  </button>
                ) : null}
                <button
                  className="pill solid-blue pull-right"
                  onClick={
                    this.state.step === 0
                      ? () => this.handleNext(role)
                      : this.handleSubmit
                  }
                >
                  {this.state.step === 0 ? "Next" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default AddDocuments;
