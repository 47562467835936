import React, { Component } from 'react';
import { toTitle } from '../utils';

import cx from 'classnames';

import { getValidity, getValidityClassName } from './editable';

export class CustomDOB extends Component {

  handleChange = (e) => {
    this.props.input.onChange(e.target.value);
  };

  render() {
    const iconElement =
      typeof this.props.icon !== 'undefined' &&
      this.props.icon.substring(0, 2) !== 'fa' ? (
        <span className="input-group-addon">{this.props.icon}</span>
      ) : typeof this.props.icon !== 'undefined' ? (
        <span className="input-group-addon">
          <i className={this.props.icon} />
        </span>
      ) : null;

    return (
      <div
        className={cx('form-group', getValidityClassName(this.props.meta))}
        key={this.props.input.name}
      >
        <label>{toTitle(this.props.label)}</label>
        <div className="input-group date">
          {iconElement}
          <input
            value={this.props.input.value}
            type="date"
            className="form-control pull-right"
            id="datepicker"
            onChange={this.handleChange}
          />
        </div>
        <div
          style={{
            color: 'gray',
            maxWidth: '100%',
            marginBottom: '5px',
          }}
        >
          {this.props.helper}
        </div>
        {getValidity(this.props.meta) && (
          <span style={{ color: '#dd4b39' }}>{this.props.meta.error}</span>
        )}
      </div>
    );
  }
}
