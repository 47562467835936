import React, { Component } from 'react';

class Item extends Component {

  createMarkup(text) {
    return { __html: text };
  }
  
  render() {
    return (
      <article
        className='row'
        style={styles.container}>
        <section className="col-xs-4 col-sm-3 centre" >
          <img
            style={styles.imgStyle}
            height={styles.imgHeight}
            alt=''
            src={this.props.img}
          />
        </section>
        <div className="col-xs-8 col-sm-9"
          style={styles.titleContainer}
          onClick={this.onEdit}>
          {this.props.title && <div style={styles.title} >{this.props.title}</div>}
          {this.props.subtitle && <div>{this.props.subtitle}</div>}
          {this.props.subSubtitle && <div>{this.props.subSubtitle}</div>}
          {this.props.description && <p style={styles.description}>{this.props.description}</p>}
        </div>
      </article>
    )
  }
}


const styles = {
  container: {
    display: "flex",
    alignItems: "center"
  },
  imgStyle: {
    margin: "1.2rem"
  },
  imgHeight: "85px",
  title: {
    fontWeight: 700,
    fontSize: '16px'
  },
  titleContainer: {
    padding: '1rem 0px'
  },
  description: { paddingTop: '1rem' }
}

export default Item;
