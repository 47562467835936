import { connect } from "react-redux";
import App from "./App";

import {
  login,
  logout,
  hideNavbar,
  logging,
} from "./reduxState/actions/index";
import { readUserData } from "./reduxState/actions/userData";
import { getConfig } from "./reduxState/actions/config";

const mapStatetoProps = (state) => ({
  user: state.user,
  userData: state.userData,
  dashboardUI: state.dashboardUI,
});

const mapStateToDispatch = {
  readUserData,
  getConfig,
  login,
  logout,
  hideNavbar,
  logging,
};

const AppContainer = connect(mapStatetoProps, mapStateToDispatch)(App);

export default AppContainer;
