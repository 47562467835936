import { addUserData, userDataLoading, editUserPicture } from './userData';

const baseUrl = process.env.REACT_APP_PROS_API_ENDPOINT;

const fetchLogosIndex = () =>
  fetch("https://cdn.jaga-me.com/assets/organisations/index.json")
    .then(res => res.json())
    .catch(error => console.error(error))

const updateConfig = org_logos => {
  return {
    type: 'UPDATE_CONFIG',
    payload: { org_logos }
  }
};

const updateProfilePicture = (picture_url) => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = localStorage.getItem('id_token');
  const uri = `${baseUrl}/user/${profile.email}`;
  const options = {
    method: 'PUT',
    headers: new Headers({
      'Authorization': token,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      'picture': picture_url
    })
  }

  return fetch(uri, options)
    .then(res => res.json())
    .catch(error => console.error(error))
}

export const updateProfile = (img) => {
  return (dispatch) => {
    dispatch(userDataLoading(true))
    updateProfilePicture(img)
      .then(json => {
        dispatch(editUserPicture(json.picture))
      })
  }
};

export const updateProfileAndReloadPage = (img) => {
  return (dispatch) => {
    dispatch(userDataLoading(true))
    updateProfilePicture(img)
      .then(json => {
        dispatch(editUserPicture(json.picture))
      })
      // A desperate fix to reload basics form initial values
      .then(() => window.location.reload())
  }
};

export const notifySuccess = state => {
  return {
    type: 'NOTIFY_SUCCESS',
    payload: state
  }
}

// education

export const updateEducation = education => {
  return {
    type: 'UPDATE_EDUCATION',
    payload: education
  }
}

export const addEducation = index => {
  return {
    type: 'ADD_EDUCATION',
    payload: index
  }
}

export const deleteEducation = index => {
  return {
    type: 'DELETE_EDUCATION',
    payload: index
  }
}

export const editEducation = arg => {
  return {
    type: 'EDIT_EDUCATION',
    payload: arg
  }
}

export const itemAction = (type, payload) => {
  return {
    type,
    payload
  }
}

export const updateBankAccount = (payload) => {
  return {
    type: 'UPDATE_BANK_ACCOUNT',
    payload
  }
}

export const getOrgLogos = () => {
  return (dispatch) => {
    fetchLogosIndex()
      .then(json =>
        dispatch(updateConfig(json.data))
      )
  }
}