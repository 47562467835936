import React, { Component } from 'react';

class EditBankInfo extends Component {
    render() {
        return (
            <section className="box-body" >
            <div className='box-body' style={{ marginBottom: '70px' }}>
                <p>To make changes to your bank account, please email <a href='mailto:jagapros@jaga-me.com' >jagapros@jaga-me.com</a> and provide the following information:</p>
                <ol>
                    <li>Name</li>
                    <li>NRIC/FIN</li>
                    <li>New Bank Name</li>
                    <li>New Bank Account Number</li>
                    <li>Attach a copy of your bank statement that shows the bank name, your name and account number in the email.</li>
                </ol>
                </div>
            </section>
        )
    }
};

export default EditBankInfo;